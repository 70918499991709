import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ProtectedRoute } from "./components/ProtectedRoute";

// Import scss
import "./assets/scss/theme.scss";

// Pages
import NotificationsPage from "./pages/NotificationsPage";
import SomePage from "./pages/SomePage";
import AffiliateDetailPage from "./pages/affiliate/AffiliateEditPage";
import AffiliatePayoutsPage from "./pages/affiliate/AffiliatePayoutsPage";
import AffiliatePurchaseSharesPage from "./pages/affiliate/AffiliatePurchaseSharesPage";
import AffiliatesPage from "./pages/affiliate/AffiliatesPage";
import AcceptInvitationPage from "./pages/auth/AcceptInvitationPage";
import PasswordResetPage from "./pages/auth/PasswordResetPage";
import RequestPasswordResetPage from "./pages/auth/RequestPasswordResetPage";
import SignInPage from "./pages/auth/SignInPage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import PayoutsPage from "./pages/payouts/PayoutsPage";
import PositionListPage from "./pages/positions/PositionListPage";
import ProfilePage from "./pages/profile/ProfilePage";
import ProfileSettingsPage from "./pages/profile/ProfileSettingsPage";
import PurchasesPage from "./pages/purchases/PurchasesPage";
import SubscriptionDetailsPage from "./pages/subscription/SubscriptionDetailsPage";
import SubscriptionEditPage from "./pages/subscription/SubscriptionEditPage";
import SubscriptionIndexDetailsPage from "./pages/subscription/SubscriptionIndexDetailsPage";
import SubscriptionProgressPage from "./pages/subscription/SubscriptionProgressPage";
import SubscriptionsPage from "./pages/subscription/SubscriptionsPage";
import CronJobsPage from "./pages/system/CronJobsPage";
import SystemLogPage from "./pages/system/SystemLogPage";
import UsersPage from "./pages/user/UsersPage";

export class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Routes>
          <Route path="sign-in" element={<SignInPage />} />
          <Route path="forgot-password" element={<RequestPasswordResetPage />} />
          <Route path="reset-password" element={<PasswordResetPage />} />
          <Route path="invitation" element={<AcceptInvitationPage />} />

          <Route path="/" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />

          <Route path="/dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />

          {/* Subscription Pages */}
          <Route path="/subscriptions" element={<ProtectedRoute><SubscriptionsPage /></ProtectedRoute>} />
          <Route path="/subscriptions/:userSubscriptionId/edit" element={<ProtectedRoute><SubscriptionEditPage /></ProtectedRoute>} />
          <Route path="/subscriptions/:userSubscriptionId/details" element={<ProtectedRoute><SubscriptionDetailsPage /></ProtectedRoute>} /> {/* TODO : delete this route */}
          <Route path="/subscriptions/:userSubscriptionId/progress" element={<ProtectedRoute><SubscriptionProgressPage /></ProtectedRoute>} />
          <Route path="/subscriptions/:userSubscriptionId/index/:userSubscriptionStageIndexId" element={<ProtectedRoute><SubscriptionIndexDetailsPage /></ProtectedRoute>} />
          {/* Subscription Pages */}

          {/* User Pages */}
          <Route path="/users" element={<ProtectedRoute><UsersPage /></ProtectedRoute>} />
          {/* User Pages */}

          {/* Affiliate Pages */}
          <Route path="/affiliates/payouts" element={<ProtectedRoute><AffiliatePayoutsPage /></ProtectedRoute>} />
          <Route path="/affiliates/shares" element={<ProtectedRoute><AffiliatePurchaseSharesPage /></ProtectedRoute>} />
          <Route path="/affiliates" element={<ProtectedRoute><AffiliatesPage /></ProtectedRoute>} />
          <Route path="/affiliates/:userAffiliateId" element={<ProtectedRoute><AffiliateDetailPage /></ProtectedRoute>} />
          {/* Affiliate Pages */}

          <Route path="/purchases" element={<ProtectedRoute><PurchasesPage /></ProtectedRoute>} />

          <Route path="/payouts" element={<ProtectedRoute><PayoutsPage /></ProtectedRoute>} />

          {/* System Pages */}
          <Route path="system/logs" element={<ProtectedRoute><SystemLogPage /></ProtectedRoute>} />
          <Route path="system/cron-jobs" element={<ProtectedRoute><CronJobsPage /></ProtectedRoute>} />
          {/* System Pages */}

          {/* Profile Pages */}
          <Route path="profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
          <Route path="profile/settings" element={<ProtectedRoute><ProfileSettingsPage /></ProtectedRoute>} />
          {/* Profile Pages */}

          {/* Other Pages */}
          <Route path="positions" element={<ProtectedRoute><PositionListPage /></ProtectedRoute>} />
          {/* Other Pages */}

          {/* Common Secured Pages */}
          <Route path="notifications" element={<ProtectedRoute><NotificationsPage /></ProtectedRoute>} />
          {/* Common Secured Pages */}

          <Route path="some" element={<ProtectedRoute><SomePage /></ProtectedRoute>} />

        </Routes>
        <ToastContainer />
      </React.Fragment>
    );
  }
}
