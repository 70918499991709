import React, { ComponentType, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router";

interface WithRouterProps {
  location?: ReturnType<typeof useLocation>;
  params?: ReturnType<typeof useParams>;
  navigate?: ReturnType<typeof useNavigate>;
}

function withRouterHook<T extends WithRouterProps>(Component: ComponentType<T>) {
  return (props: T) => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();

    // Optional: You can perform additional logic here using the router props

    useEffect(() => {
      // Optional: You can perform additional logic here when the route changes

      return () => {
        // Optional: You can perform cleanup logic here when the component unmounts
      };
    }, [location]);

    return <Component {...props} location={location} params={params} navigate={navigate} />;
  };
}

export default withRouterHook;
