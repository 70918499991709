import { Component } from "react";
import { SubscriptionStatus } from "../../lib/drivers/dto/enums/SubscriptionStatus";
import { UserAffiliatePayoutStatus } from "../../lib/drivers/dto/enums/UserAffiliatePayoutStatus";
import { UserAffiliatePurchaseStatus } from "../../lib/drivers/dto/enums/UserAffiliatePurchaseStatus";
import { SubscriptionPayoutStatus } from "../../lib/drivers/dto/enums/SubscriptionPayoutStatus";

interface IProps {
  enumType: "UserAffiliatePurchaseStatus" | "UserAffiliatePayoutStatus" | "SubscriptionStatus" | "SubscriptionPayoutStatus";
  value: UserAffiliatePurchaseStatus | UserAffiliatePayoutStatus | SubscriptionStatus | SubscriptionPayoutStatus;
}

export class EnumBadgeControl extends Component<IProps> {
  getSubscriptionPayoutStatus(value: SubscriptionPayoutStatus) {
    let color = "";

    switch (value) {
      case SubscriptionPayoutStatus.COMPLETED:
        color = "success";
        break;

        case SubscriptionPayoutStatus.PROCESSING:
        color = "primary";
        break;

        case SubscriptionPayoutStatus.UNDER_REVIEW:
        color = "info";
        break;

        case SubscriptionPayoutStatus.PENDING:
        color = "warning";
        break;

      default:
        break;
    }

    return <span className={`badge bg-${color}`}>{value}</span>;
  }

  getSubscriptionStatus(value: SubscriptionStatus) {
    let badgeClass = "";

    switch (value) {
      case SubscriptionStatus.ACTIVE:
        badgeClass = "badge-soft-success";
        break;
      case SubscriptionStatus.DRAFT:
        badgeClass = "badge-soft-warning";
        break;
      case SubscriptionStatus.READY:
        badgeClass = "badge-soft-primary";
        break;
      case SubscriptionStatus.FINISHED:
        badgeClass = "badge-soft-info";
        break;
      case SubscriptionStatus.PAID:
        badgeClass = "badge-soft-info";
        break;

      default:
        badgeClass = "badge-soft-danger";
        break;
    }

    return <span className={`badge ${badgeClass}`}>{value}</span>;
  }

  getUserAffiliatePurchaseStatus(value: UserAffiliatePurchaseStatus) {
    let color = "";

    switch (value) {
      case UserAffiliatePurchaseStatus.NOT_SET:
        color = "primary";
        break;
      case UserAffiliatePurchaseStatus.APPROVED:
        color = "warning";
        break;
      case UserAffiliatePurchaseStatus.REJECTED:
        color = "danger";
        break;
      case UserAffiliatePurchaseStatus.PROCESSED:
        color = "info";
        break;
      default:
        color = "primary";
        break;
    }
    
    return <span className={`badge bg-${color}`}>{value}</span>;
  }

  getUserAffiliatePayoutStatus(value: UserAffiliatePayoutStatus) {
    let color = "";

    switch (value) {
      case UserAffiliatePayoutStatus.NOT_SET:
        color = "primary";
        break;
      case UserAffiliatePayoutStatus.PENDING:
        color = "primary";
        break;
      case UserAffiliatePayoutStatus.PROCESSING:
        color = "warning";
        break;
      case UserAffiliatePayoutStatus.COMPLETED:
        color = "danger";
        break;
      case UserAffiliatePayoutStatus.UNDER_REVIEW:
        color = "info";
        break;
      default:
        color = "primary";
        break;
    }

    return <span className={`badge bg-${color}`}>{value}</span>;
  }

  render() {
    const { value, enumType } = this.props;
    return (
      <div>
        {enumType === "UserAffiliatePurchaseStatus" && this.getUserAffiliatePurchaseStatus(value as UserAffiliatePurchaseStatus)}
        {enumType === "UserAffiliatePayoutStatus" && this.getUserAffiliatePayoutStatus(value as UserAffiliatePayoutStatus)}
        {enumType === "SubscriptionStatus" && this.getSubscriptionStatus(value as SubscriptionStatus)}
        {enumType === "SubscriptionPayoutStatus" && this.getSubscriptionPayoutStatus(value as SubscriptionPayoutStatus)}
      </div>
    );
  }
}
