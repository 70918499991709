import "flatpickr/dist/themes/material_blue.css";
import React from "react";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import LoaderComponent from "../../components/LoaderComponent";
import StackedColumnChart from "../../components/common/StackedColumnChart";
import TableContainer from "../../components/common/TableContainer";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { ViewUserSubscriptionProgressInfoDto } from "../../lib/drivers/dto/subscriptions/ViewUserSubscriptionProgressInfoDto";
import withRouterHook from "../../withRouterHook";

export type DashboardPageProps = {
  location?: ReturnType<typeof useLocation>;
};

type SeriesItem = {
  name: string;
  data: number[];
};

type ReportItem = {
  title: string;
  iconClass: string;
  description: string;
};

type DashboardPageState = {
  isLoading: boolean;
  reportDetails: ReportItem[];
  chartData: {
    categories: string[];
    series: SeriesItem[];
  } | null;
  tableData: Array<{
    ctimeStamp: string;
    contractName: string;
    leverageLevel: number;
    volume: string;
    pnl: string;
  }>;
};

class DashboardPage extends React.Component<DashboardPageProps, DashboardPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: DashboardPageState = {
    isLoading: true,
    reportDetails: [],
    chartData: null,
    tableData: []
  };

  async componentDidMount() {
    try {
      // Concurrent data fetching
      const [userSubscriptionsResponse, totalUsersResponse] = await Promise.all([
        PortCentralServer.Repo.client.filterViewUserSubscriptionProgressInfos({}),
        PortCentralServer.Repo.client.getUserCount()
      ]);

      const userSubscriptions = userSubscriptionsResponse.data;
      const totalUsers = totalUsersResponse.toLocaleString();

      const reportDetails: ReportItem[] = [
        { title: "Total Users", iconClass: "bx-user", description: totalUsers }
      ];

      let totalOrders = 0;
      let totalVolume = 0;

      // Process subscriptions data
      const chartCategories = new Set<string>();
      const chartSeriesMap = new Map<string, { name: string; data: number[] }>();
      const tableData: DashboardPageState["tableData"] = [];

      await Promise.all(
        userSubscriptions.map(async (userSubscription: ViewUserSubscriptionProgressInfoDto) => {
          const { userSubscriptionId, currentStageIndexId, fundTradingProgramTitle, currentStageIndexTotalOrders, currentStageIndexTradingVolume } =
            userSubscription;

          totalOrders += currentStageIndexTotalOrders;
          totalVolume += Number(currentStageIndexTradingVolume);

          if (userSubscriptionId && currentStageIndexId) {
            const [stageDaysData, stageDayOrdersData] = await Promise.all([
              PortCentralServer.Repo.client.getUserSubscriptionStageIndexDays(userSubscriptionId, currentStageIndexId),
              PortCentralServer.Repo.client.getUserSubscriptionStageIndexDayOrders(userSubscriptionId, currentStageIndexId)
            ]);

            // Process chart data
            stageDaysData.forEach((item) => {
              const dateKey = `${item.year}-${item.month}-${item.day}`;
              chartCategories.add(dateKey);
              if (!chartSeriesMap.has(item.userSubscriptionStageIndexId)) {
                chartSeriesMap.set(item.userSubscriptionStageIndexId, {
                  name: fundTradingProgramTitle,
                  data: []
                });
              }
              const series = chartSeriesMap.get(item.userSubscriptionStageIndexId)!;
              series.data.push(Number(Number(item.totalRealizedPnl).toFixed(6)));
            });

            // Process table data
            tableData.push(
              ...stageDayOrdersData.data.map((item) => ({
                ctimeStamp: new Date(item.ctimeStamp).toLocaleString("en-US", { hour12: false }),
                contractName: item.contractName,
                leverageLevel: item.leverageLevel,
                volume: Number(item.volume).toLocaleString("en-US", { maximumFractionDigits: 2 }),
                pnl: Number(item.pnl).toFixed(6).toString()
              }))
            );
          }
        })
      );

      // Add aggregated reports
      reportDetails.push({ title: "Total Orders", iconClass: "bx-copy-alt", description: totalOrders.toLocaleString() });
      reportDetails.push({ title: "Total Volume", iconClass: "bx-copy-alt", description: `${totalVolume.toFixed(2)} USDT` });

      this.setState({
        reportDetails,
        chartData: {
          categories: Array.from(chartCategories),
          series: Array.from(chartSeriesMap.values())
        },
        tableData
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  renderReports = () => (
    <Row className="justify-content-center">
      {this.state.reportDetails.map((detail, key) => (
        <Col key={key}>
          <Card className="mini-stats-wid">
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p className="fw-medium">{detail.title}</p>
                  <h4 className="mb-0">{detail.description}</h4>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );

  renderChart = () => {
    const { chartData } = this.state;

    if (!chartData) return null;

    return (
      <CardBody>
        <h5 className="card-title me-2">Total PNL (USDT)</h5>
        <hr className="mb-4" />
        <div id="area-chart" dir="ltr">
          <StackedColumnChart
            categories={chartData.categories}
            periodData={chartData.series}
            dataColors='["--bs-primary", "--bs-warning", "--bs-success"]'
          />
        </div>
      </CardBody>
    );
  };

  renderTable = () => {
    const columns = [
      { Header: "Timestamp", accessor: "ctimeStamp" },
      { Header: "Contract Name", accessor: "contractName" },
      { Header: "Leverage Level", accessor: "leverageLevel" },
      { Header: "Volume (USDT)", accessor: "volume" },
      { Header: "PNL (USDT)", accessor: "pnl" }
    ];

    return (
      <CardBody>
        <div className="mb-4 h4 card-title">Latest Progress</div>
        <TableContainer columns={columns} data={this.state.tableData} isGlobalFilter={false} isAddOptions={false} customPageSize={20} />
      </CardBody>
    );
  };

  render() {
    const { isLoading } = this.state;

    return (
      <Layout>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Account" breadcrumbItem="Progress" />
            <Row>
              <Col xl="12">
                {isLoading ? (
                  <LoaderComponent />
                ) : (
                  <>
                    {this.renderReports()}
                    <Row>
                      <Card>{this.renderChart()}</Card>
                    </Row>
                    <Row>{this.renderTable()}</Row>
                  </>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouterHook(DashboardPage);
