import moment from "moment";
import React from "react";
import { Col, Row } from "reactstrap";
import { BaseModal } from "../../../components/modals/BaseModal";
import { UserAffiliatePurchaseShareDto } from "../../../lib/drivers/dto/affiliate/UserAffiliatePurchaseShareDto";
import { UserAffiliatePurchaseStatus } from "../../../lib/drivers/dto/enums/UserAffiliatePurchaseStatus";

export type ModalSetPurchaseStatusArgs = {
  comment: string;
};

export type ModalSetPurchaseStatusProps = {
  selectedPurchases: UserAffiliatePurchaseShareDto[];
  newStatus: UserAffiliatePurchaseStatus;
  payoutNetwork: string;
  payoutAddress: string;
  onCloseClick(): void;
  onSubmitClick(args: ModalSetPurchaseStatusArgs): void;
};

type ModalSetPurchaseStatusState = {
  here: boolean;
};

export class ModalSetPurchaseStatus extends React.Component<ModalSetPurchaseStatusProps, ModalSetPurchaseStatusState> {
  state: ModalSetPurchaseStatusState = {
    here: true
  };

  refComment: React.RefObject<HTMLTextAreaElement>;

  constructor(props: ModalSetPurchaseStatusProps) {
    super(props);

    this.refComment = React.createRef();
  }

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    this.setState((prevState) => {
      return { here: true };
    });
  };

  componentDidUpdate = async (prevProps: ModalSetPurchaseStatusProps, prevState: ModalSetPurchaseStatusState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  onCopyTextClick = async (e: React.FormEvent<HTMLButtonElement>, text: string) => {
    e.preventDefault();

    if (navigator.clipboard && navigator.clipboard.writeText) {
      try {
        await navigator.clipboard.writeText(text);
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    } else {
      // Fallback for older browsers
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }
      document.body.removeChild(textarea);
    }
  };

  onCloseClick = () => {
    if (this.props.onCloseClick) {
      this.props.onCloseClick();
    }
  };

  onSubmitClick = () => {
    const comment: string = this.refComment.current ? this.refComment.current.value : "";

    const args: ModalSetPurchaseStatusArgs = {
      comment: comment
    };

    if (this.props.onSubmitClick) {
      this.props.onSubmitClick(args);
    }
  };
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    const purchaseShareAmountSum = this.props.selectedPurchases.reduce((sum, ele) => sum + Number(ele.purchaseShareAmount), 0);

    return (
      <React.Fragment>
        <BaseModal
          isOpen={true}
          size="lg"
          title="Reject Purchase"
          onCloseClick={this.onCloseClick}
          onSubmitClick={this.onSubmitClick}
          content={
            <>
              <Row className="mb-4">
                <Col sm={12}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Share amount</th>
                        <th>Date</th>
                        <th>Buying User</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(this.props.selectedPurchases || []).map((item: UserAffiliatePurchaseShareDto, key: number) => {
                        return (
                          <tr key={key}>
                            <td>{item.purchaseShareAmount} USDT</td>
                            <td>{moment(item.dateCreated).format("YYYY-MM-DD HH:mm:ss")}</td>
                            <td>{item.buyingUserEmail}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Col>
              </Row>

              <Row className="mb-4">
                <label className="col-sm-3 col-form-label">Comment</label>
                <Col sm={9}>
                  <textarea ref={this.refComment} className="form-control" placeholder="Comment" rows={4} maxLength={1000} />
                </Col>
              </Row>

              {this.props.newStatus == UserAffiliatePurchaseStatus.PROCESSED && (
                <>
                  <hr />
                  <Row>
                    <Col>
                      <h5>Payout network</h5>
                      <p>
                        {this.props.payoutNetwork ? (
                          <>
                            <span>{this.props.payoutNetwork}</span>
                            <button type="button" className="btn btn-sm mx-1" onClick={(e) => this.onCopyTextClick(e, this.props.payoutNetwork)}>
                              <i className="bx bx-copy" />
                            </button>
                          </>
                        ) : (
                          <span className="text-danger">
                            <i className="bx bx-error"></i> not provided
                          </span>
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h5>Payout address</h5>
                      <p>
                        {this.props.payoutAddress ? (
                          <>
                            <span>{this.props.payoutAddress}</span>
                            <button type="button" className="btn btn-sm mx-1" onClick={(e) => this.onCopyTextClick(e, this.props.payoutAddress)}>
                              <i className="bx bx-copy" />
                            </button>
                          </>
                        ) : (
                          <span className="text-danger">
                            <i className="bx bx-error"></i> not provided
                          </span>
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h5>Payout amount</h5>
                      <p>
                        {purchaseShareAmountSum} USDT
                        <button type="button" className="btn btn-sm mx-1" onClick={(e) => this.onCopyTextClick(e, purchaseShareAmountSum.toString())}>
                          <i className="bx bx-copy" />
                        </button>
                      </p>
                    </Col>
                  </Row>
                </>
              )}
            </>
          }
        />
      </React.Fragment>
    );
  }
}
