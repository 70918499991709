import axios, { AxiosRequestConfig } from 'axios';
import { AuthCookieManager } from '../AuthCookieManager';
import { ServerEndpointBuilder } from "../core/ServerDriver/ServerEndpointBuilder";
import { PathParamPair, UrlParamPair } from '../core/ServerDriver/UrlParamPair';
import { CentralServerEndpointKind, CentralServerPathParamKind } from "./CentralServerClientEnums";
import { ApproveUserAffiliatePurchaseSharesRequestDto } from './dto/affiliate/ApproveUserAffiliatePurchaseSharesRequestDto';
import { FilterUserAffiliatePayoutRequestDto } from './dto/affiliate/FilterUserAffiliatePayoutRequestDto';
import { FilterUserAffiliatePayoutResponseDto } from './dto/affiliate/FilterUserAffiliatePayoutResponseDto';
import { FilterUserAffiliatePurchaseShareRequestDto } from './dto/affiliate/FilterUserAffiliatePurchaseShareRequestDto';
import { FilterUserAffiliatePurchaseShareResponseDto } from './dto/affiliate/FilterUserAffiliatePurchaseShareResponseDto';
import { FilterUserAffiliateRequestDto } from './dto/affiliate/FilterUserAffiliateRequestDto';
import { FilterUserAffiliateResponseDto } from './dto/affiliate/FilterUserAffiliateResponseDto';
import { RejectUserAffiliatePurchaseSharesRequestDto } from './dto/affiliate/RejectUserAffiliatePurchaseSharesRequestDto';
import { UserAffiliateDto } from './dto/affiliate/UserAffiliateDto';
import { AcceptInvitationRequestDto } from './dto/auth/AcceptInvitationRequestDto';
import { AcceptInvitationResponseDto } from './dto/auth/AcceptInvitationResponseDto';
import { ChangePasswordRequestDto } from './dto/auth/ChangePasswordRequestDto';
import { ChangePasswordResponseDto } from './dto/auth/ChangePasswordResponseDto';
import { CreatePasswordResetDto } from "./dto/auth/CreatePasswordResetDto";
import { CreatePasswordResetResponseDto } from './dto/auth/CreatePasswordResetResponseDto';
import { PasswordResetRequestDto } from "./dto/auth/PasswordResetRequestDto";
import { PasswordResetResponseDto } from './dto/auth/PasswordResetResponseDto';
import { SignInDto } from "./dto/auth/SignInDto";
import { SignInResponseDto } from './dto/auth/SignInResponseDto';
import { FilterFutureOrderDto } from './dto/central/FilterFutureOrderDto';
import { FilterFutureOrderItemResponseDto } from './dto/central/FilterFutureOrderItemResponseDtos';
import { FilterFuturePositionDto } from './dto/central/FilterFuturePositionDto';
import { FilterFuturePositionItemResponseDto } from './dto/central/FilterFuturePositionItemResponseDto';
import { PlatformUserDto } from './dto/central/PlatformUserDto';
import { FilterReportActualFuturePositionRequestDto } from './dto/reporting/FilterReportActualFuturePositionRequestDto';
import { ReportActualFuturePositionItemDto } from './dto/reporting/ReportActualFuturePositionItemDto';
import { FilterUserSubscriptionPayoutResponseDto } from './dto/subscriptions/FilterUserSubscriptionPayoutResponseDto';
import { FilterUserSubscriptionPurchaseRequestDto } from './dto/subscriptions/FilterUserSubscriptionPurchaseRequestDto';
import { FilterUserSubscriptionPurchaseResponseDto } from './dto/subscriptions/FilterUserSubscriptionPurchaseResponseDto';
import { FilterUserSubscriptionRequestDto } from './dto/subscriptions/FilterUserSubscriptionRequestDto';
import { FilterViewUserSubscriptionProgressInfoRequestDto } from './dto/subscriptions/FilterViewUserSubscriptionProgressInfoRequestDto';
import { FilterViewUserSubscriptionProgressInfoResponseDto } from './dto/subscriptions/FilterViewUserSubscriptionProgressInfoResponseDto';
import { SubscriptionIndexSetReadyDto } from './dto/subscriptions/SubscriptionIndexSetReadyDto';
import { SubscriptionSetDisabledDto } from './dto/subscriptions/SubscriptionSetDisabledDto';
import { SubscriptionSetReadyDto } from './dto/subscriptions/SubscriptionSetReadyDto';
import { UserBadgeDto } from './dto/subscriptions/UserBadgeDto';
import { UserSubscriptionCredentialDto } from './dto/subscriptions/UserSubscriptionCredentialDto';
import { UserSubscriptionDto } from './dto/subscriptions/UserSubscriptionDto';
import { UserSubscriptionPayoutCompleteRequestDto } from './dto/subscriptions/UserSubscriptionPayoutCompleteRequestDto';
import { UserSubscriptionPayoutDto } from './dto/subscriptions/UserSubscriptionPayoutDto';
import { UserSubscriptionStageIndexDayDto } from './dto/subscriptions/UserSubscriptionStageIndexDayDto';
import { FilterUserSubscriptionStageIndexDayOrderDto } from './dto/subscriptions/UserSubscriptionStageIndexDayOrderDto';
import { UserSubscriptionStageIndexDto } from './dto/subscriptions/UserSubscriptionStageIndexDto';
import { ViewUserSubscriptionProgressInfoDto } from './dto/subscriptions/ViewUserSubscriptionProgressInfoDto';
import { LogRequestDto } from './dto/system/LogRequestDto';
import { LogResponseDto } from './dto/system/LogResponseDto';
import { TaskResponseDto } from './dto/system/TaskResponseDto';
import { FilterUserRequestDto } from './dto/user/FilterUserRequestDto';
import { FilterUserResponseDto } from './dto/user/FilterUserResponseDto';

type CentralErrorHandler = (error: unknown) => void;

export class CentralServerClient {
    // #region Private fields
    private readonly _endpointBuilder: ServerEndpointBuilder;
    // private _accessToken: string;
    // #endregion

    // #region Properties
    public get endpointBuilder(): ServerEndpointBuilder {
        return this._endpointBuilder;
    }

    // public get accessToken(): string {
    //     return this._accessToken;
    // }

    // public set accessToken(value: string) {
    //     this._accessToken = value;
    // }

    errorHandler?: CentralErrorHandler;
    // #endregion

    // #region Constructor
    constructor() {
        this._endpointBuilder = new ServerEndpointBuilder(process.env.REACT_APP_BASE_URL_API);
    }
    // #endregion

    // #region Auth Endpoints
    async authSignIn(model: SignInDto): Promise<SignInResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_sign_in.toString(), null, null);

            const { data } = await axios.post(url, model);
            const result: SignInResponseDto = data as SignInResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async authRequestPasswordReset(model: CreatePasswordResetDto): Promise<CreatePasswordResetResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_request_password_reset.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: CreatePasswordResetResponseDto = data as CreatePasswordResetResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async authResetPassword(model: PasswordResetRequestDto): Promise<PasswordResetResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_reset_password.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: PasswordResetResponseDto = data as PasswordResetResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async authAcceptInvitation(model: AcceptInvitationRequestDto): Promise<AcceptInvitationResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_accept_invitation.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: AcceptInvitationResponseDto = data as AcceptInvitationResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region UserSubscriptions Endpoints
    async filterViewUserSubscriptionProgressInfos(model: FilterViewUserSubscriptionProgressInfoRequestDto): Promise<FilterViewUserSubscriptionProgressInfoResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_view_filter.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterViewUserSubscriptionProgressInfoResponseDto = data as FilterViewUserSubscriptionProgressInfoResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getViewUserSubscriptionProgressInfoById(userSubscriptionId: string): Promise<ViewUserSubscriptionProgressInfoDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_view_by_id.toString(), [
                new PathParamPair(CentralServerPathParamKind.userSubscriptionId, userSubscriptionId)
            ], null);

            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            const result: ViewUserSubscriptionProgressInfoDto = data as ViewUserSubscriptionProgressInfoDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async activateUserSubscription(model: SubscriptionSetReadyDto): Promise<UserSubscriptionDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscription_activate.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async activateUserSubscriptionIndex(model: SubscriptionIndexSetReadyDto): Promise<UserSubscriptionStageIndexDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscription_stage_activate.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async deactivateUserSubscription(model: SubscriptionSetDisabledDto): Promise<UserSubscriptionDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscription_deactivate.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterUserSubscriptionPayouts(model: FilterUserSubscriptionRequestDto): Promise<FilterUserSubscriptionPayoutResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_payouts_filter.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterUserSubscriptionPurchases(model: FilterUserSubscriptionPurchaseRequestDto): Promise<FilterUserSubscriptionPurchaseResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_purchases_filter.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserSubscriptionCredentialById(userSubscriptionCredentialId: string): Promise<UserSubscriptionCredentialDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_credentials_by_id.toString(), [
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionCredentialId, userSubscriptionCredentialId)
            ], null);

            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    // async getUserSubscriptionPurchase(userId: string, userSubscriptionPayoutId: string): Promise<UserSubscriptionPayoutDto> {
    //     try {
    //         const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_payouts_by_user.toString(), [
    //             new UrlParamPair(CentralServerPathParamKind.userId, userId),
    //             new UrlParamPair(CentralServerPathParamKind.userSubscriptionPayoutId, userSubscriptionPayoutId)
    //         ], null);

    //         const headers = this.getHeaders();

    //         const { data } = await axios.get(url, headers);

    //         return data;
    //     } catch (error) {
    //         if (this.errorHandler) {
    //             this.errorHandler(error);
    //         }
    //         else throw error;
    //     }
    // }

    async completeUserSubscriptionPurchase(model: UserSubscriptionPayoutCompleteRequestDto): Promise<UserSubscriptionPayoutDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_payouts_complete.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserBadge(): Promise<UserBadgeDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_badge.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserSubscriptionStagesData(userSubscriptionId: string): Promise<UserSubscriptionStageIndexDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_stages.toString(), [
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionId, userSubscriptionId),
            ], null);

            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserSubscriptionStageIndex(userSubscriptionId: string, userSubscriptionStageIndexId: string): Promise<UserSubscriptionStageIndexDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_stage_index.toString(), [
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionId, userSubscriptionId),
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionStageIndexId, userSubscriptionStageIndexId)
            ], null);

            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserSubscriptionStageIndexDays(userSubscriptionId: string, userSubscriptionStageIndexId: string): Promise<UserSubscriptionStageIndexDayDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_stage_index_days.toString(), [
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionId, userSubscriptionId),
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionStageIndexId, userSubscriptionStageIndexId)
            ], null);

            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserSubscriptionStageIndexDayOrders(userSubscriptionId: string, userSubscriptionStageIndexId: string): Promise<FilterUserSubscriptionStageIndexDayOrderDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_stage_index_day_orders.toString(), [
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionId, userSubscriptionId),
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionStageIndexId, userSubscriptionStageIndexId)
            ], null);

            const headers = this.getHeaders();

            const { data } = await axios.post(url, null, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Users
    async filterUsers(model: FilterUserRequestDto): Promise<FilterUserResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.users_filter.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterUserResponseDto = data as FilterUserResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserCount(): Promise<number> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.users_count.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            const result: number = data as number;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region User Affiliates
    async filterUserAffiliates(model: FilterUserAffiliateRequestDto): Promise<FilterUserAffiliateResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.affiliate_filter.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterUserAffiliateResponseDto = data as FilterUserAffiliateResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserAffiliate(userAffiliateId: string): Promise<UserAffiliateDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.affiliate_by_id.toString(), [
                new PathParamPair(CentralServerPathParamKind.userAffiliateId, userAffiliateId)
            ], null);

            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            const result: UserAffiliateDto = data as UserAffiliateDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterUserAffiliatePayouts(model: FilterUserAffiliatePayoutRequestDto): Promise<FilterUserAffiliatePayoutResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.affiliates_payouts_filter.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterUserAffiliatePayoutResponseDto = data as FilterUserAffiliatePayoutResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterUserAffiliatePurchaseShares(model: FilterUserAffiliatePurchaseShareRequestDto): Promise<FilterUserAffiliatePurchaseShareResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.affiliates_purchases_shares_filter.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterUserAffiliatePurchaseShareResponseDto = data as FilterUserAffiliatePurchaseShareResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async approveUserAffiliatePurchaseShares(model: ApproveUserAffiliatePurchaseSharesRequestDto): Promise<boolean> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.affiliates_purchases_shares_approve.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: boolean = data as boolean;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async rejectUserAffiliatePurchaseShares(model: RejectUserAffiliatePurchaseSharesRequestDto): Promise<boolean> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.affiliates_purchases_shares_reject.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: boolean = data as boolean;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Profile Endpoints
    async profileChangePassword(model: ChangePasswordRequestDto): Promise<ChangePasswordResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.profile_change_password.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.patch(url, model, headers);
            const result: ChangePasswordResponseDto = data as ChangePasswordResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region System Endpoints
    async filterLogList(model: LogRequestDto): Promise<LogResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.logs_filter.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: LogResponseDto = data as LogResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getTaskList(): Promise<TaskResponseDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.tasks.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            const result: TaskResponseDto[] = data as TaskResponseDto[];

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region central
    async centralGetPlatformUserById(platformUserUid: string): Promise<PlatformUserDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.central_platform_user_get_by_id.toString(), [
                new PathParamPair(CentralServerPathParamKind.platformUserUid, platformUserUid)
            ], null);

            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            const result: PlatformUserDto = data as PlatformUserDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async centralFilterFutureOrders(model: FilterFutureOrderDto): Promise<FilterFutureOrderItemResponseDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.central_future_orders_filter.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterFutureOrderItemResponseDto[] = data as FilterFutureOrderItemResponseDto[];

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async centralFilterFuturePositions(model: FilterFuturePositionDto): Promise<FilterFuturePositionItemResponseDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.central_future_positions_filter.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterFuturePositionItemResponseDto[] = data as FilterFuturePositionItemResponseDto[];

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Reporting
    async centralFilterReportActualFuturePosition(model: FilterReportActualFuturePositionRequestDto): Promise<ReportActualFuturePositionItemDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.reporting_central_filter_actual_future_position.toString(), null, null);

            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: ReportActualFuturePositionItemDto[] = data as ReportActualFuturePositionItemDto[];

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Private Functions
    private getHeaders(): AxiosRequestConfig {
        const token = AuthCookieManager.getToken();

        const headers: AxiosRequestConfig = {};

        if (token) {
            headers.headers = {
                Authorization: `Bearer ${token}`
            };
        }

        return headers;
    }
    // #endregion
}