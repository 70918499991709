import moment from "moment";
import React from "react";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import { UserSubscriptionStageIndexDayDto } from "../../../lib/drivers/dto/subscriptions/UserSubscriptionStageIndexDayDto";
import FormattedNumber from "../../../components/controls/FormattedNumber";
import { SubscriptionIndexFlags } from "../../../components/controls/SubscriptionIndexFlags";

export type UserSubscriptionStageIndexDayListComponentProps = {
  list: UserSubscriptionStageIndexDayDto[];
};

type UserSubscriptionStageIndexDayListComponentState = {};

export class UserSubscriptionStageIndexDayListComponent extends React.Component<UserSubscriptionStageIndexDayListComponentProps, UserSubscriptionStageIndexDayListComponentState> {
  state: UserSubscriptionStageIndexDayListComponentState = {};

  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: UserSubscriptionStageIndexDayListComponentProps, prevState: UserSubscriptionStageIndexDayListComponentState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <h5 className="fw-semibold">Payouts</h5>
            <Row>
              <Col xl={12}>
                <div className="table-responsive">
                  <Table className="table table-bordered m-0">
                    <thead>
                      <tr>
                        <th></th>
                        <th>status</th>
                        <th>dateCreated</th>
                        <th>dateSynced</th>
                        <th>year</th>
                        <th>month</th>
                        <th>day</th>
                        <th>totalOrders</th>
                        <th>tradingVolume</th>
                        <th>totalRealizedPnl</th>
                        <th>currentProfitPercentage</th>
                        <th>flags</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(this.props.list || []).map((item: UserSubscriptionStageIndexDayDto, key: number) => {
                        return (
                          <tr key={key}>
                            <td></td>
                            <td>{item.status}</td>
                            <td>{moment(item.dateCreated).format("YYYY-MM-DD HH:mm:ss")}</td>
                            <td>{moment(item.dateSynced).format("YYYY-MM-DD HH:mm:ss")}</td>
                            <td>{item.year}</td>
                            <td>{item.month}</td>
                            <td>{item.day}</td>
                            <td>{item.totalOrders}</td>
                            <td>{item.tradingVolume}</td>
                            <td>
                              <FormattedNumber value={item.totalRealizedPnl} />
                            </td>
                            <td>
                              <FormattedNumber value={item.currentProfitPercentage} />
                            </td>
                            <td>
                              <SubscriptionIndexFlags flags={item.flags} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}
