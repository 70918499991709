import React, { Component } from 'react';
import { SubscriptionIndexFlagType } from '../../lib/drivers/dto/enums/SubscriptionIndexFlagType';

type Props = {
  flags: SubscriptionIndexFlagType[] | null;
};

export class SubscriptionIndexFlags extends Component<Props> {
  render() {
    // const { value } = this.props;

    if (this.props.flags && this.props.flags.length > 0) {
      return (
        <>
          {this.props.flags.map((flag, index) => (
            <React.Fragment key={index}>
              <span className="badge bg-danger rounded-pill">{flag}</span>
              <br />
            </React.Fragment>
          ))}
        </>
      );
    }

    return null;
  }
}

