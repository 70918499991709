import moment from "moment";
import React from "react";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import { EnumBadgeControl } from "../../../components/controls/EnumBadgeControl";
import { UserInfoButtons } from "../../../components/controls/UserInfoButtons";
import { UserAffiliatePurchaseShareDto } from "../../../lib/drivers/dto/affiliate/UserAffiliatePurchaseShareDto";
import { UserAffiliatePurchaseStatus } from "../../../lib/drivers/dto/enums/UserAffiliatePurchaseStatus";
import { ModalSetPurchaseStatus, ModalSetPurchaseStatusArgs } from "./ModalSetPurchaseStatus";
import { UserAffiliateDto } from "../../../lib/drivers/dto/affiliate/UserAffiliateDto";
import { PortCentralServer } from "../../../lib/domain/Ports/PortCentralServer";

export type UserAffiliatePurchaseShareListComponentProps = {
  userAffiliateItem: UserAffiliateDto;
  list: UserAffiliatePurchaseShareDto[];

  onUserAffiliatePurchaseDecision(): void;
};

type UserAffiliatePurchaseShareListComponentState = {
  // functionality
  selectedPurchases: { [key: string]: UserAffiliatePurchaseShareDto | undefined };

  // actions
  openModalSetPurchaseStatus: boolean;
  newPurchaseStatus: UserAffiliatePurchaseStatus;
};

export class UserAffiliatePurchaseShareListComponent extends React.Component<UserAffiliatePurchaseShareListComponentProps, UserAffiliatePurchaseShareListComponentState> {
  state: UserAffiliatePurchaseShareListComponentState = {
    // functionality
    selectedPurchases: {},

    // actions
    openModalSetPurchaseStatus: false,
    newPurchaseStatus: UserAffiliatePurchaseStatus.NOT_SET
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: UserAffiliatePurchaseShareListComponentProps, prevState: UserAffiliatePurchaseShareListComponentState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  handlePurchaseSelection = async (purchase: UserAffiliatePurchaseShareDto) => {
    await this.setState((prevState) => {
      const current = prevState.selectedPurchases[purchase.userAffiliatePurchaseShareId];
      const updatedSelections = { ...prevState.selectedPurchases };

      if (current) {
        delete updatedSelections[purchase.userAffiliatePurchaseShareId]; // Deselecting the purchase
      } else {
        updatedSelections[purchase.userAffiliatePurchaseShareId] = purchase; // Selecting the purchase
      }

      return { selectedPurchases: updatedSelections };
    });
  };
  // #endregion

  // #region State Setters
  // #endregion

  // #region Actions
  onActionSetUserAffiliatePurchaseStatusClick = async (e: React.FormEvent<HTMLButtonElement>, newStatus: UserAffiliatePurchaseStatus) => {
    e.preventDefault();

    await this.setState((prevState) => {
      return {
        openModalSetPurchaseStatus: true,
        newPurchaseStatus: newStatus
      };
    });
  };

  onModalSetPurchaseStatusCloseClick = async () => {
    await this.setState((prevState) => {
      return { openModalSetPurchaseStatus: false };
    });
  };

  onModalSetPurchaseStatusSubmitClick = async (args: ModalSetPurchaseStatusArgs) => {
    if (this.state.newPurchaseStatus == UserAffiliatePurchaseStatus.PROCESSED) {
      const userAffiliatePurchaseShareIds: string[] = [];
      let totalPayoutAmount: number = 0;

      for (const purchase of Object.values(this.state.selectedPurchases)) {
        if (purchase) {
          userAffiliatePurchaseShareIds.push(purchase.userAffiliatePurchaseShareId);

          totalPayoutAmount += Number(purchase.purchaseShareAmount);
        }
      }

      const res = await PortCentralServer.Repo.client.approveUserAffiliatePurchaseShares({
        userAffiliateId: this.props.userAffiliateItem.userAffiliateId,
        userAffiliatePurchaseShareIds: userAffiliatePurchaseShareIds,
        payoutAmount: totalPayoutAmount,
        comment: args.comment
      });

      if (res) {
        if (this.props.onUserAffiliatePurchaseDecision) {
          await this.props.onUserAffiliatePurchaseDecision();
        }
        
        await this.setState((prevState) => {
          return { openModalSetPurchaseStatus: false };
        });
      }
    } else if (this.state.newPurchaseStatus == UserAffiliatePurchaseStatus.REJECTED) {
      const userAffiliatePurchaseShareIds: string[] = [];

      for (const purchase of Object.values(this.state.selectedPurchases)) {
        if (purchase) {
          userAffiliatePurchaseShareIds.push(purchase.userAffiliatePurchaseShareId);
        }
      }

      const res = await PortCentralServer.Repo.client.rejectUserAffiliatePurchaseShares({
        userAffiliateId: this.props.userAffiliateItem.userAffiliateId,
        userAffiliatePurchaseShareIds: userAffiliatePurchaseShareIds,
        comment: args.comment
      });

      if (res) {
        if (this.props.onUserAffiliatePurchaseDecision) {
          await this.props.onUserAffiliatePurchaseDecision();
        }

        await this.setState((prevState) => {
          return { openModalSetPurchaseStatus: false };
        });
      }
    }
  };
  // #endregion

  // #region Functions
  hasSelectedPurchases = () => {
    return Object.keys(this.state.selectedPurchases).length > 0;
  };
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <h5 className="fw-semibold">Purchase shares</h5>
            <Row>
              <Col xl={12}>
                <div className="table-responsive">
                  <Table className="table table-bordered m-0">
                    <thead>
                      <tr>
                        <th>
                          <button
                            className="btn btn-sm btn-warning"
                            disabled={!this.hasSelectedPurchases()}
                            onClick={(e) => this.onActionSetUserAffiliatePurchaseStatusClick(e, UserAffiliatePurchaseStatus.PROCESSED)}
                          >
                            Approve
                          </button>{" "}
                          <button
                            className="btn btn-sm btn-danger"
                            disabled={!this.hasSelectedPurchases()}
                            onClick={(e) => this.onActionSetUserAffiliatePurchaseStatusClick(e, UserAffiliatePurchaseStatus.REJECTED)}
                          >
                            Reject
                          </button>
                        </th>
                        <th>purchasePurchaseAmount USDT</th>
                        <th>purchaseSharePercentage</th>
                        <th>purchaseShareAmount USDT</th>
                        <th>status</th>
                        <th>datePurchase</th>
                        <th>dateCreated</th>
                        <th>buyingUserUserId</th>
                        <th>buyingUserEmail</th>
                        <th>buyingUserInvitationCode</th>
                        <th>comment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(this.props.list || []).map((item: UserAffiliatePurchaseShareDto, key: number) => {
                        const isSelected = !!this.state.selectedPurchases[item.userAffiliatePurchaseShareId];
                        const rowClass = isSelected ? "table-warning" : "";

                        return (
                          <tr key={key}>
                            <td className={rowClass}>
                              {item.status == UserAffiliatePurchaseStatus.APPROVED ||
                                (item.status == UserAffiliatePurchaseStatus.NOT_SET && (
                                  <input type="checkbox" checked={isSelected} onChange={() => this.handlePurchaseSelection(item)} />
                                ))}
                            </td>
                            <td className={rowClass}>{item.purchasePaymentAmount}</td>
                            <td className={rowClass}>{item.purchaseSharePercentage}</td>
                            <td className={rowClass}>{item.purchaseShareAmount}</td>
                            <td className={rowClass}>
                              <EnumBadgeControl enumType="UserAffiliatePurchaseStatus" value={item.status} />
                            </td>
                            <td className={rowClass}>{item.datePayment ? moment(item.datePayment).format("YYYY-MM-DD HH:mm:ss") : ""}</td>
                            <td className={rowClass}>{moment(item.dateCreated).format("YYYY-MM-DD HH:mm:ss")}</td>
                            <td className={rowClass}>
                              <UserInfoButtons userId={item.buyingUserUserId} />
                            </td>
                            <td className={rowClass}>{item.buyingUserEmail}</td>
                            <td className={rowClass}>{item.buyingUserInvitationCode}</td>
                            <td className={rowClass}>{item.comment}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        {this.state.openModalSetPurchaseStatus && (
          <ModalSetPurchaseStatus
            payoutNetwork={this.props.userAffiliateItem.payoutNetwork}
            payoutAddress={this.props.userAffiliateItem.payoutAddress}
            onCloseClick={this.onModalSetPurchaseStatusCloseClick}
            onSubmitClick={this.onModalSetPurchaseStatusSubmitClick}
            selectedPurchases={Object.values(this.state.selectedPurchases)}
            newStatus={this.state.newPurchaseStatus}
          />
        )}
      </React.Fragment>
    );
  }
}
