import React from "react";

type FormattedNumberProps = {
  value: number;
};

class FormattedNumber extends React.Component<FormattedNumberProps> {
  formatNumber(value: number): string {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 8,
    }).format(value);
  }

  render() {
    const { value } = this.props;
    const textClass = value < 0 ? "text-danger" : value > 0 ? "text-success" : "";

    return <span className={textClass}>{this.formatNumber(value)}</span>;
  }
}

export default FormattedNumber;
