import React from "react";
import { BaseModal } from "../../../components/modals/BaseModal";

export type ModalUserSubscriptionDeactivateArgs = {
  message: string;
};

export type ModalUserSubscriptionDeactivateProps = {
  userSubscriptionId: string;
  onCloseClick(): void;
  onSubmitClick(args: ModalUserSubscriptionDeactivateArgs): void;
};

type ModalUserSubscriptionDeactivateState = {};

export class ModalUserSubscriptionDeactivate extends React.Component<ModalUserSubscriptionDeactivateProps, ModalUserSubscriptionDeactivateState> {
  state: ModalUserSubscriptionDeactivateState = {};

  refMessage: React.RefObject<HTMLTextAreaElement>;

  constructor(props: ModalUserSubscriptionDeactivateProps) {
    super(props);

    this.refMessage = React.createRef();
  }

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    this.setState((prevState) => {
      return { here: true };
    });
  };

  componentDidUpdate = async (prevProps: ModalUserSubscriptionDeactivateProps, prevState: ModalUserSubscriptionDeactivateState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  onCloseClick = () => {
    if (this.props.onCloseClick) {
      this.props.onCloseClick();
    }
  };

  onSubmitClick = () => {
    const message: string = this.refMessage.current ? this.refMessage.current.value : "";

    const args: ModalUserSubscriptionDeactivateArgs = {
      message: message
    };

    if (this.props.onSubmitClick) {
      this.props.onSubmitClick(args);
    }
  };
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <BaseModal
          isOpen={true}
          content={
            <>
              <textarea ref={this.refMessage} className="form-control" placeholder="Comment" rows={4} maxLength={1000} />
            </>
          }
          title="Confirm Subscription Deactivation"
          onSubmitClick={this.onSubmitClick}
          onCloseClick={this.onCloseClick}
        />
      </React.Fragment>
    );
  }
}
