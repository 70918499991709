import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import { SubscriptionIndexFlags } from "../../../components/controls/SubscriptionIndexFlags";
import { BaseModal } from "../../../components/modals/BaseModal";
import { PortCentralServer } from "../../../lib/domain/Ports/PortCentralServer";
import { UserSubscriptionCredentialDto } from "../../../lib/drivers/dto/subscriptions/UserSubscriptionCredentialDto";
import { UserSubscriptionStageIndexDto } from "../../../lib/drivers/dto/subscriptions/UserSubscriptionStageIndexDto";

export type UserSubscriptionStageIndexListComponentProps = {
  list: UserSubscriptionStageIndexDto[];
};

type UserSubscriptionStageIndexListComponentState = {
  // actions
  openModalCredentialView: boolean;
  selectedUserSubscriptionCredentialItem: UserSubscriptionCredentialDto;
};

export class UserSubscriptionStageIndexListComponent extends React.Component<UserSubscriptionStageIndexListComponentProps, UserSubscriptionStageIndexListComponentState> {
  state: UserSubscriptionStageIndexListComponentState = {
    // actions
    openModalCredentialView: false,
    selectedUserSubscriptionCredentialItem: null
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: UserSubscriptionStageIndexListComponentProps, prevState: UserSubscriptionStageIndexListComponentState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  onCredentialViewClick = async (e: React.FormEvent<HTMLButtonElement>, item: UserSubscriptionStageIndexDto) => {
    e.preventDefault();

    const dataUserSubscriptionCredentialItem = await PortCentralServer.Repo.client.getUserSubscriptionCredentialById(item.userSubscriptionCredentialId);

    if (dataUserSubscriptionCredentialItem) {
      this.setState({
        selectedUserSubscriptionCredentialItem: dataUserSubscriptionCredentialItem,
        openModalCredentialView: true
      });
    }

    await this.setState((prevState) => {
      return {
        openModalCredentialView: true
      };
    });
  };

  onModalCredentialViewCloseClick = async () => {
    await this.setState((prevState) => {
      return { openModalCredentialView: false };
    });
  };
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <h5 className="fw-semibold">Payouts</h5>
            <Row>
              <Col xl={12}>
                <div className="table-responsive">
                  <Table className="table table-bordered m-0">
                    <thead>
                      <tr>
                        <th></th>
                        <th>stageSequence</th>
                        <th>stageType</th>
                        <th>dateFrom</th>
                        <th>dateTo</th>
                        <th>status</th>
                        <th>totalOrders</th>
                        <th>tradingVolume</th>
                        <th>totalRealizedPnl</th>
                        <th>allowProfitClaimYn</th>
                        <th>profitSplitAmount</th>
                        <th>flags</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(this.props.list || []).map((item: UserSubscriptionStageIndexDto, key: number) => {
                        return (
                          <tr key={key}>
                            <td>
                              <button className="btn btn-sm btn-info me-2" onClick={(e) => this.onCredentialViewClick(e, item)}>
                                Credentials
                              </button>
                              <Link to={`/subscriptions/${item.userSubscriptionId}/index/${item.userSubscriptionStageIndexId}`} className="btn btn-sm btn-info me-2">
                                Details
                              </Link>
                              {/* <Link to={`/subscriptions/${item.userSubscriptionId}/index/${item.userSubscriptionStageIndexId}/days`} className="btn btn-sm btn-info me-2">
                                Days
                              </Link> */}
                            </td>
                            <td>{item.stageSequence + 1}</td>
                            <td>{item.stageType}</td>
                            <td>{item.dateFrom ? moment(item.dateFrom).format("YYYY-MM-DD HH:mm:ss") : ""}</td>
                            <td>{item.dateTo ? moment(item.dateTo).format("YYYY-MM-DD HH:mm:ss") : ""}</td>
                            <td>{item.status}</td>
                            <td>{item.totalOrders}</td>
                            <td>{Number(item.tradingVolume).toLocaleString()}</td>
                            <td>{Number(item.totalRealizedPnl).toLocaleString()}</td>
                            <td>{item.allowProfitClaimYn}</td>
                            <td>{Number(item.profitSplitAmount)}</td>
                            <td>
                              <SubscriptionIndexFlags flags={item.flags} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        {/* UserSubscriptionCredential */}
        <BaseModal
          isOpen={this.state.openModalCredentialView}
          content={
            <div>
              {this.state.selectedUserSubscriptionCredentialItem && (
                <React.Fragment>
                  <p>
                    <strong>Platform kind:</strong> {this.state.selectedUserSubscriptionCredentialItem.platformKind}
                  </p>
                  <p>
                    <strong>Uid:</strong> {this.state.selectedUserSubscriptionCredentialItem.uid}
                  </p>
                  <p>
                    <strong>Username:</strong> {this.state.selectedUserSubscriptionCredentialItem.username}
                  </p>
                  <p>
                    <strong>Password:</strong> {this.state.selectedUserSubscriptionCredentialItem.password}
                  </p>
                  <p>
                    <strong>2FA:</strong> {this.state.selectedUserSubscriptionCredentialItem.mfa}
                  </p>
                </React.Fragment>
              )}
            </div>
          }
          title="See credentials"
          onSubmitClick={this.onModalCredentialViewCloseClick}
          onCloseClick={this.onModalCredentialViewCloseClick}
        />
        {/* UserSubscriptionCredential */}
      </React.Fragment>
    );
  }
}
