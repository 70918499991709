import React from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";

export type SidebarContentProps = {};

type SidebarContentState = {};

export class SidebarContent extends React.Component<SidebarContentProps, SidebarContentState> {
  ref: React.RefObject<SimpleBar>;

  constructor(props: SidebarContentProps) {
    super(props);

    this.ref = React.createRef();
  }

  render() {
    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={this.ref}>
          <div id="sidebar-menu">
            <ul className="list-unstyled" id="side-menu">
              <li>
                <Link to="/dashboard">
                  <i className="bx bx-file"></i>
                  <span>Dashboard</span>
                </Link>
              </li>

              <li>
                <Link to="/subscriptions">
                  <i className="bx bx-file"></i>
                  <span>Subscriptions</span>
                </Link>
              </li>
              <li>
                <Link to="/purchases">
                  <i className="bx bx-dollar"></i>
                  <span>Purchases</span>
                </Link>
              </li>
              <li>
                <Link to="/payouts">
                  <i className="bx bx-dollar"></i>
                  <span>Payouts</span>
                </Link>
              </li>

              <li>
                <Link to="/users">
                  <i className="bx bx-user"></i>
                  <span>Users</span>
                </Link>
              </li>

              <li>
                <Link to="/positions">
                  <i className="bx bx-timer"></i>
                  <span>Positions</span>
                </Link>
              </li>

              <li>
                <Link to="" className="has-arrow" aria-expanded="true">
                  <i className="bx bx-analyse"></i>
                  <span>Affiliates</span>
                </Link>
                <ul className="sub-menu mm-collapse mm-show">
                  <li>
                    <Link to="/affiliates">List</Link>
                  </li>
                  <li>
                    <Link to="/affiliates/shares">Fee shares</Link>
                  </li>
                  <li>
                    <Link to="/affiliates/payouts">Payouts</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    );
  }
}
