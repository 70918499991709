import moment from "moment";
import React from "react";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import { EnumBadgeControl } from "../../../components/controls/EnumBadgeControl";
import { SubscriptionPayoutStatus } from "../../../lib/drivers/dto/enums/SubscriptionPayoutStatus";
import { UserSubscriptionPayoutDto } from "../../../lib/drivers/dto/subscriptions/UserSubscriptionPayoutDto";
import { ModalUserSubscriptionPayoutComplete, ModalUserSubscriptionPayoutCompleteArgs } from "./ModalUserSubscriptionPayoutComplete";
import { PortCentralServer } from "../../../lib/domain/Ports/PortCentralServer";

export type UserSubscriptionPayoutListComponentProps = {
  list: UserSubscriptionPayoutDto[];

  onPayoutComplete(): void;
};

type UserSubscriptionPayoutListComponentState = {
  openModalUserSubscriptionPayoutComplete: boolean;
  selectedUserSubscriptionPayout: UserSubscriptionPayoutDto | null;
};

export class UserSubscriptionPayoutListComponent extends React.Component<UserSubscriptionPayoutListComponentProps, UserSubscriptionPayoutListComponentState> {
  state: UserSubscriptionPayoutListComponentState = {
    openModalUserSubscriptionPayoutComplete: false,
    selectedUserSubscriptionPayout: null
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: UserSubscriptionPayoutListComponentProps, prevState: UserSubscriptionPayoutListComponentState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Actions
  onActionUserSubscriptionPayoutCompleteClick = async (e: React.FormEvent<HTMLButtonElement>, item: UserSubscriptionPayoutDto) => {
    e.preventDefault();

    await this.setState((prevState) => {
      return {
        openModalUserSubscriptionPayoutComplete: true,
        selectedUserSubscriptionPayout: item
      };
    });
  };

  onModalUserSubscriptionPayoutCompleteCloseClick = async () => {
    await this.setState((prevState) => {
      return {
        openModalUserSubscriptionPayoutComplete: false
      };
    });
  };

  onModalUserSubscriptionPayoutCompleteSubmitClick = async (args: ModalUserSubscriptionPayoutCompleteArgs) => {
    const payout = await PortCentralServer.Repo.client.completeUserSubscriptionPurchase({
      userSubscriptionPayoutId: this.state.selectedUserSubscriptionPayout.userSubscriptionPayoutId,
      comment: args.comment
    });

    if (payout) {
      await this.setState((prevState) => {
        return {
          openModalUserSubscriptionPayoutComplete: false
        };
      });

      if (this.props.onPayoutComplete) {
        await this.props.onPayoutComplete();
      }
    }
  };
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <h5 className="fw-semibold">Payouts</h5>
            <Row>
              <Col xl={12}>
                <div className="table-responsive">
                  <Table className="table table-bordered m-0">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Status</th>
                        <th>Date created</th>
                        <th>Date payment</th>
                        <th>Payout amount</th>
                        <th>Network</th>
                        <th>Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(this.props.list || []).map((item: UserSubscriptionPayoutDto, key: number) => {
                        return (
                          <tr key={key}>
                            <td>
                              {item.status == SubscriptionPayoutStatus.PENDING && (
                                <React.Fragment>
                                  <button className="btn btn-sm btn-primary" onClick={(e) => this.onActionUserSubscriptionPayoutCompleteClick(e, item)}>
                                    Complete
                                  </button>
                                </React.Fragment>
                              )}
                            </td>
                            <td>
                              <EnumBadgeControl enumType="SubscriptionPayoutStatus" value={item.status} />
                            </td>
                            <td>{moment(item.dateCreated).format("YYYY-MM-DD HH:mm:ss")}</td>
                            <td>{item.datePayment ? moment(item.datePayment).format("YYYY-MM-DD HH:mm:ss") : ""}</td>
                            <td>{item.payoutAmount} USDT</td>
                            <td>{item.network}</td>
                            <td>{item.address}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        {this.state.openModalUserSubscriptionPayoutComplete && this.state.selectedUserSubscriptionPayout && (
          <ModalUserSubscriptionPayoutComplete
            entity={this.state.selectedUserSubscriptionPayout}
            onCloseClick={this.onModalUserSubscriptionPayoutCompleteCloseClick}
            onSubmitClick={this.onModalUserSubscriptionPayoutCompleteSubmitClick}
          />
        )}
      </React.Fragment>
    );
  }
}
