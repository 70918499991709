import moment from "moment";
import React from "react";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import { EnumBadgeControl } from "../../../components/controls/EnumBadgeControl";
import { UserInfoButtons } from "../../../components/controls/UserInfoButtons";
import { UserAffiliatePayoutDto } from "../../../lib/drivers/dto/affiliate/UserAffiliatePayoutDto";

export type UserAffiliatePayoutListComponentProps = {
  list: UserAffiliatePayoutDto[];
};

type UserAffiliatePayoutListComponentState = {};

export class UserAffiliatePayoutListComponent extends React.Component<UserAffiliatePayoutListComponentProps, UserAffiliatePayoutListComponentState> {
  state: UserAffiliatePayoutListComponentState = {};

  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: UserAffiliatePayoutListComponentProps, prevState: UserAffiliatePayoutListComponentState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <h5 className="fw-semibold">Payouts</h5>
            <Row>
              <Col xl={12}>
                <div className="table-responsive">
                  <Table className="table table-bordered m-0">
                    <thead>
                      <tr>
                        <th>userId</th>
                        <th>email</th>
                        <th>userAffiliatePayoutId</th>
                        <th>invitationCode</th>
                        <th>status</th>
                        <th>network</th>
                        <th>address</th>
                        <th>payoutAmount USDT</th>
                        <th>datePayment</th>
                        <th>dateCreated</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(this.props.list || []).map((item: UserAffiliatePayoutDto, key: number) => {
                        return (
                          <tr key={key}>
                            <td>
                              <UserInfoButtons userId={item.userId} />
                            </td>
                            <td>{item.email}</td>
                            <td>{item.userAffiliatePayoutId}</td>
                            <td>{item.invitationCode}</td>
                            <td>
                              <EnumBadgeControl enumType="UserAffiliatePayoutStatus" value={item.status} />
                            </td>
                            <td>{item.network}</td>
                            <td>{item.address}</td>
                            <td>{item.payoutAmount}</td>
                            <td>{item.datePayment ? moment(item.datePayment).format("YYYY-MM-DD HH:mm:ss") : ""}</td>
                            <td>{moment(item.dateCreated).format("YYYY-MM-DD HH:mm:ss")}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}
