import "flatpickr/dist/themes/material_blue.css";
import moment from "moment";
import React from "react";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import UniversalNumberFormatter from "../../components/controls/UniversalNumberFormatter";
import LoaderComponent from "../../components/LoaderComponent";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { ReportActualFuturePositionItemDto } from "../../lib/drivers/dto/reporting/ReportActualFuturePositionItemDto";
import withRouterHook from "../../withRouterHook";
import { UserSubscriptionButtons } from "../../components/controls/UserSubscriptionButtons";
import { UserInfoButtons } from "../../components/controls/UserInfoButtons";

type PositionListPageProps = {
  location?: ReturnType<typeof useLocation>;
};

type PositionListPageState = {
  isLoading: boolean;
  list: ReportActualFuturePositionItemDto[];
};

class PositionListPage extends React.Component<PositionListPageProps, PositionListPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: PositionListPageState = {
    isLoading: true,
    list: []
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    const response = await PortCentralServer.Repo.client.centralFilterReportActualFuturePosition({
      endDate: moment().utc().endOf("day").toDate(),
      startDate: moment().utc().subtract(30, "minutes").toDate()
    });

    if (response) {
      await this.setState((prevState) => {
        return {
          list: response,
          isLoading: false
        };
      });
    } else {
      await this.setState((prevState) => {
        return {
          isLoading: false
        };
      });
    }
  };

  componentDidUpdate = async (prevProps: PositionListPageProps, prevState: PositionListPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  // #region Renders
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Positions" breadcrumbItem="List" />
              <Row>
                <Col xl={12}>
                  {this.state.isLoading ? (
                    <LoaderComponent />
                  ) : (
                    <>
                      <Card>
                        <CardBody>
                          <Row>
                            <Col xl={12}>
                              <div className="table-responsive">
                                <Table className="table table-bordered m-0">
                                  <thead>
                                    <tr>
                                      <th>userId</th>
                                      <th>userSubscriptionId</th>
                                      <th>userSubscriptionStageIndexId</th>
                                      <th>id</th>
                                      <th>ctimeStamp</th>
                                      <th>originUid</th>
                                      <th>contractName</th>
                                      <th>side</th>
                                      <th>positionType</th>
                                      <th>positionBalance</th>
                                      <th>holdAmount</th>
                                      <th>status</th>
                                      <th>openRealizedAmount</th>
                                      <th>leverageLevel</th>
                                      <th>volume</th>
                                      <th>unRealizedAmount</th>
                                      <th>tradeFee</th>
                                      <th>realizedAmount</th>
                                      <th>openPrice</th>
                                      <th>closePrice</th>
                                      <th>avgPrice</th>
                                      <th>positionValue </th>
                                      <th>closeProfit</th>
                                      <th>buy</th>
                                      <th>closeVolume</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {(this.state.list || []).map((item: ReportActualFuturePositionItemDto, key: number) => {
                                      return (
                                        <tr key={key}>
                                          <th>
                                            <UserInfoButtons userId={item.userId} />
                                          </th>
                                          <th>
                                            <UserSubscriptionButtons userSubscriptionId={item.userSubscriptionId} />
                                          </th>
                                          <th>{item.userSubscriptionStageIndexId}</th>
                                          <td>{`${item.id}`}</td>
                                          <td>{moment(Number(item.ctimeStamp)).format("YYYY-MM-DD HH:mm:ss")}</td>
                                          <td>{`${item.originUid}`}</td>
                                          <td>{item.contractName}</td>
                                          <td>{item.side}</td>
                                          <td>{item.positionType}</td>
                                          <td>
                                            <UniversalNumberFormatter value={item.positionBalance} />
                                          </td>
                                          <td>
                                            <UniversalNumberFormatter value={item.holdAmount} />
                                          </td>
                                          <td>{item.status}</td>
                                          <td>
                                            <UniversalNumberFormatter value={item.openRealizedAmount} />
                                          </td>
                                          <td>{item.leverageLevel}</td>
                                          <td>{item.volume}</td>
                                          <td>
                                            <UniversalNumberFormatter value={item.unRealizedAmount} highlight={true} />
                                          </td>
                                          <td>
                                            <UniversalNumberFormatter value={item.tradeFee} highlight={false} />
                                          </td>
                                          <td>
                                            <UniversalNumberFormatter value={item.realizedAmount} highlight={true} />
                                          </td>
                                          <td>
                                            <UniversalNumberFormatter value={item.openPrice} highlight={false} />
                                          </td>
                                          <td>
                                            <UniversalNumberFormatter value={item.closePrice} highlight={false} />
                                          </td>
                                          <td>
                                            <UniversalNumberFormatter value={item.avgPrice} highlight={false} />
                                          </td>
                                          <td>
                                            <UniversalNumberFormatter value={item.positionValue} highlight={false} />
                                          </td>
                                          <td>{item.closeProfit}</td>
                                          <td>{item.buy}</td>
                                          <td>{item.closeVolume}</td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(PositionListPage);
