import classnames from "classnames";
import "flatpickr/dist/themes/material_blue.css";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import LoaderComponent from "../../../components/LoaderComponent";
import { AppContext } from "../../../context/AppProvider";
import { Layout } from "../../../layout";
import { Breadcrumbs } from "../../../layout/Breadcrumbs";
import { PortCentralServer } from "../../../lib/domain/Ports/PortCentralServer";
import { FilterFuturePositionItemResponseDto } from "../../../lib/drivers/dto/central/FilterFuturePositionItemResponseDto";
import { UserSubscriptionStageIndexDayDto } from "../../../lib/drivers/dto/subscriptions/UserSubscriptionStageIndexDayDto";
import { UserSubscriptionStageIndexDto } from "../../../lib/drivers/dto/subscriptions/UserSubscriptionStageIndexDto";
import withRouterHook from "../../../withRouterHook";
import { CentralFuturePositionListComponent } from "./CentralFuturePositionListComponent";
import { UserSubscriptionStageIndexDayListComponent } from "./UserSubscriptionStageIndexDayListComponent";
import { UserSubscriptionStageIndexItemComponent } from "./UserSubscriptionStageIndexItemComponent";
import moment from "moment";
import { UserSubscriptionCredentialDto } from "../../../lib/drivers/dto/subscriptions/UserSubscriptionCredentialDto";

type SubscriptionIndexDetailsPageParams = ReturnType<typeof useParams> & {
  userSubscriptionId: string;
  userSubscriptionStageIndexId: string;
};

export type SubscriptionIndexDetailsPageProps = {
  location?: ReturnType<typeof useLocation>;
  params?: SubscriptionIndexDetailsPageParams;
};

type SubscriptionIndexDetailsPageState = {
  // page
  activeTab: string;

  // loading
  isLoadingUserSubscriptionStageIndexItem: boolean;
  isLoadingUserSubscriptionStageIndexDayList: boolean;
  isLoadingFuturePositionList: boolean;

  // data
  dataUserSubscriptionStageIndexItem: UserSubscriptionStageIndexDto;
  dataUserSubscriptionCredentialItem: UserSubscriptionCredentialDto;
  dataUserSubscriptionStageIndexDayList: UserSubscriptionStageIndexDayDto[];
  dataFuturePositionList: FilterFuturePositionItemResponseDto[];
};

class SubscriptionIndexDetailsPage extends React.Component<SubscriptionIndexDetailsPageProps, SubscriptionIndexDetailsPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: SubscriptionIndexDetailsPageState = {
    // page
    activeTab: "1",

    // loading
    isLoadingUserSubscriptionStageIndexItem: true,
    isLoadingUserSubscriptionStageIndexDayList: true,
    isLoadingFuturePositionList: true,

    // data
    dataUserSubscriptionStageIndexItem: null,
    dataUserSubscriptionCredentialItem: null,
    dataUserSubscriptionStageIndexDayList: null,
    dataFuturePositionList: null
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    await this.loadUserSubscriptionStageIndexItem();
    await this.loadUserSubscriptionStageIndexDayList();
    await this.loadFuturePositionList();
  };

  componentDidUpdate = async (prevProps: SubscriptionIndexDetailsPageProps, prevState: SubscriptionIndexDetailsPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  toggleTab = async (tab: string) => {
    if (this.state.activeTab !== tab) {
      await this.setState({
        activeTab: tab
      });
    }
  };
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  loadUserSubscriptionStageIndexItem = async () => {
    await this.setState((prevState) => {
      return {
        isLoadingUserSubscriptionStageIndexItem: true
      };
    });

    // request
    const response = await PortCentralServer.Repo.client.getUserSubscriptionStageIndex(this.props.params.userSubscriptionId, this.props.params.userSubscriptionStageIndexId);

    if (response) {
      const responseCredentials = await PortCentralServer.Repo.client.getUserSubscriptionCredentialById(response.userSubscriptionCredentialId);

      await this.setState((prevState) => {
        return {
          dataUserSubscriptionStageIndexItem: response,
          dataUserSubscriptionCredentialItem: responseCredentials,
          isLoadingUserSubscriptionStageIndexItem: false
        };
      });
    } else {
      await this.setState((prevState) => {
        return {
          isLoadingUserSubscriptionStageIndexItem: false
        };
      });
    }
  };

  loadUserSubscriptionStageIndexDayList = async () => {
    await this.setState((prevState) => {
      return {
        isLoadingUserSubscriptionStageIndexDayList: true
      };
    });

    // request
    const response = await PortCentralServer.Repo.client.getUserSubscriptionStageIndexDays(this.props.params.userSubscriptionId, this.props.params.userSubscriptionStageIndexId);

    if (response) {
      await this.setState((prevState) => {
        return {
          dataUserSubscriptionStageIndexDayList: response.filter((ele) => ele.totalOrders > 0),
          isLoadingUserSubscriptionStageIndexDayList: false
        };
      });
    } else {
      await this.setState((prevState) => {
        return {
          isLoadingUserSubscriptionStageIndexDayList: false
        };
      });
    }
  };

  loadFuturePositionList = async () => {
    await this.setState((prevState) => {
      return {
        isLoadingFuturePositionList: true
      };
    });

    if (this.state.dataUserSubscriptionCredentialItem && this.state.dataUserSubscriptionCredentialItem.uid) {
      // request
      const response = await PortCentralServer.Repo.client.centralFilterFuturePositions({
        endDate: moment().utc().endOf("day").toDate(),
        originUid: this.state.dataUserSubscriptionCredentialItem.uid,
        startDate: moment().utc().startOf("day").toDate()
      });

      if (response) {
        await this.setState((prevState) => {
          return {
            dataFuturePositionList: response,
            isLoadingFuturePositionList: false
          };
        });
      } else {
        await this.setState((prevState) => {
          return {
            isLoadingFuturePositionList: false
          };
        });
      }
    } else {
      await this.setState((prevState) => {
        return {
          isLoadingFuturePositionList: false
        };
      });
    }
  };
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Subscriptions" breadcrumbItem="Index" />
              <Row>
                <Col xl={12}>
                  {this.state.isLoadingUserSubscriptionStageIndexItem ? (
                    <LoaderComponent />
                  ) : (
                    <>
                      <Card>
                        <CardBody>
                          <Row>
                            <Col xl={12}>
                              <UserSubscriptionStageIndexItemComponent entity={this.state.dataUserSubscriptionStageIndexItem} />
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  )}
                </Col>
              </Row>

              <div className="card-body">
                <Nav tabs>
                  <NavItem>
                    <NavLink className={classnames({ active: this.state.activeTab === "1" })} onClick={() => this.toggleTab("1")}>
                      Days
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: this.state.activeTab === "2" })} onClick={() => this.toggleTab("2")}>
                      Positions
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col xl={12}>
                        {this.state.isLoadingUserSubscriptionStageIndexDayList ? (
                          <LoaderComponent />
                        ) : (
                          <>
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col xl={12}>
                                    <UserSubscriptionStageIndexDayListComponent list={this.state.dataUserSubscriptionStageIndexDayList} />
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </>
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col xl={12}>
                        {this.state.isLoadingFuturePositionList ? (
                          <LoaderComponent />
                        ) : (
                          <>
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col xl={12}>
                                    <CentralFuturePositionListComponent list={this.state.dataFuturePositionList} />
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </>
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(SubscriptionIndexDetailsPage);
