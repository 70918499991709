import classnames from "classnames";
import "flatpickr/dist/themes/material_blue.css";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import LoaderComponent from "../../../components/LoaderComponent";
import { AppContext } from "../../../context/AppProvider";
import { Layout } from "../../../layout";
import { Breadcrumbs } from "../../../layout/Breadcrumbs";
import { PortCentralServer } from "../../../lib/domain/Ports/PortCentralServer";
import { UserAffiliateDto } from "../../../lib/drivers/dto/affiliate/UserAffiliateDto";
import { UserAffiliatePayoutDto } from "../../../lib/drivers/dto/affiliate/UserAffiliatePayoutDto";
import { UserAffiliatePurchaseShareDto } from "../../../lib/drivers/dto/affiliate/UserAffiliatePurchaseShareDto";
import { UserAffiliatePayoutStatus } from "../../../lib/drivers/dto/enums/UserAffiliatePayoutStatus";
import { UserAffiliatePurchaseStatus } from "../../../lib/drivers/dto/enums/UserAffiliatePurchaseStatus";
import withRouterHook from "../../../withRouterHook";
import { UserAffiliateItemComponent } from "./UserAffiliateItemComponent";
import { UserAffiliatePayoutListComponent } from "./UserAffiliatePayoutListComponent";
import { UserAffiliatePurchaseShareListComponent } from "./UserAffiliatePurchaseShareListComponent";

type AffiliateEditPageParams = ReturnType<typeof useParams> & {
  userAffiliateId: string;
};

type AffiliateEditPageProps = {
  location?: ReturnType<typeof useLocation>;
  params?: AffiliateEditPageParams;
};

type AffiliateEditPageState = {
  // page
  activeTab: string;

  // loading
  isLoadingDetails: boolean;
  isLoadingPurchaseShare: boolean;
  isLoadingPayouts: boolean;

  // data
  dataUserAffiliateItem: UserAffiliateDto;
  dataUserAffiliatePurchaseShareList: UserAffiliatePurchaseShareDto[];
  dataUserAffiliatePayoutList: UserAffiliatePayoutDto[];

  // totals
  totalPayoutAmount: number;
  totalPendingPayoutAmount: number;
};

class AffiliateEditPage extends React.Component<AffiliateEditPageProps, AffiliateEditPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: AffiliateEditPageState = {
    // page
    activeTab: "1",

    // loading
    isLoadingDetails: true,
    isLoadingPurchaseShare: true,
    isLoadingPayouts: true,

    // data
    dataUserAffiliateItem: null,
    dataUserAffiliatePurchaseShareList: null,
    dataUserAffiliatePayoutList: null,

    // totals
    totalPayoutAmount: 0,
    totalPendingPayoutAmount: 0
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    await this.loadUserAffiliateItem();
    await this.loadUserAffiliatePurchaseShareList();
    await this.loadUserAffiliatePayoutList();
  };

  componentDidUpdate = async (prevProps: AffiliateEditPageProps, prevState: AffiliateEditPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  toggleTab = async (tab: string) => {
    if (this.state.activeTab !== tab) {
      await this.setState({
        activeTab: tab
      });
    }
  };
  // #endregion

  // #region State Setters
  onUserAffiliatePurchaseDecision = async () => {
    await this.loadUserAffiliateItem();
    await this.loadUserAffiliatePurchaseShareList();
    await this.loadUserAffiliatePayoutList();
  };
  // #endregion

  // #region Functions
  loadUserAffiliateItem = async () => {
    await this.setState((prevState) => {
      return {
        isLoadingDetails: true
      };
    });

    // request
    const response = await PortCentralServer.Repo.client.getUserAffiliate(this.props.params.userAffiliateId);

    if (response) {
      await this.setState((prevState) => {
        return {
          dataUserAffiliateItem: response,
          isLoadingDetails: false
        };
      });
    } else {
      await this.setState((prevState) => {
        return {
          isLoadingDetails: false
        };
      });
    }
  };

  loadUserAffiliatePurchaseShareList = async () => {
    await this.setState((prevState) => {
      return {
        isLoadingPurchaseShare: true
      };
    });

    // request
    const response = await PortCentralServer.Repo.client.filterUserAffiliatePurchaseShares({ userAffiliateId: this.props.params.userAffiliateId });

    if (response && response.data) {
      const totalPendingPayoutAmount = response.data
        .filter((ele) => ele.status === UserAffiliatePurchaseStatus.NOT_SET || ele.status === UserAffiliatePurchaseStatus.APPROVED)
        .reduce((sum, ele) => sum + Number(ele.purchaseShareAmount), 0);

      await this.setState((prevState) => {
        return {
          dataUserAffiliatePurchaseShareList: response.data,
          totalPendingPayoutAmount: totalPendingPayoutAmount,
          isLoadingPurchaseShare: false
        };
      });
    } else {
      await this.setState((prevState) => {
        return {
          isLoadingPurchaseShare: false
        };
      });
    }
  };

  loadUserAffiliatePayoutList = async () => {
    await this.setState((prevState) => {
      return {
        isLoadingPayouts: true
      };
    });

    // request
    const response = await PortCentralServer.Repo.client.filterUserAffiliatePayouts({ userAffiliateId: this.props.params.userAffiliateId });

    if (response && response.data) {
      const totalPayoutAmount = response.data.filter((ele) => ele.status === UserAffiliatePayoutStatus.COMPLETED).reduce((sum, ele) => sum + Number(ele.payoutAmount), 0);

      await this.setState((prevState) => {
        return {
          dataUserAffiliatePayoutList: response.data,
          totalPayoutAmount: totalPayoutAmount,
          isLoadingPayouts: false
        };
      });
    } else {
      await this.setState((prevState) => {
        return {
          isLoadingPayouts: false
        };
      });
    }
  };
  // #endregion

  // #region Renders
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Affiliates" breadcrumbItem="Details" />
              <Row>
                <Col xl={12}>
                  {this.state.isLoadingDetails ? (
                    <LoaderComponent />
                  ) : (
                    <>
                      <Card>
                        <CardBody>
                          <Row>
                            <Col xl={12}>
                              <UserAffiliateItemComponent
                                entity={this.state.dataUserAffiliateItem}
                                totalPayoutAmount={this.state.totalPayoutAmount}
                                totalPendingPayoutAmount={this.state.totalPendingPayoutAmount}
                              />
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  )}
                </Col>
              </Row>

              <div className="card-body">
                <Nav tabs>
                  <NavItem>
                    <NavLink className={classnames({ active: this.state.activeTab === "1" })} onClick={() => this.toggleTab("1")}>
                      Purchase shares
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: this.state.activeTab === "2" })} onClick={() => this.toggleTab("2")}>
                      Payouts
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col xl={12}>
                        {this.state.isLoadingPurchaseShare ? (
                          <LoaderComponent />
                        ) : (
                          <>
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col xl={12}>
                                    <UserAffiliatePurchaseShareListComponent
                                      list={this.state.dataUserAffiliatePurchaseShareList}
                                      userAffiliateItem={this.state.dataUserAffiliateItem}
                                      onUserAffiliatePurchaseDecision={this.onUserAffiliatePurchaseDecision}
                                    />
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </>
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col xl={12}>
                        {this.state.isLoadingPayouts ? (
                          <LoaderComponent />
                        ) : (
                          <>
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col xl={12}>
                                    <UserAffiliatePayoutListComponent list={this.state.dataUserAffiliatePayoutList} />
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </>
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(AffiliateEditPage);
