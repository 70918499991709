import React from "react";
import { Component, createRef, RefObject } from "react";

// Define the structure of each menu item
interface MenuItem {
  label: string;
  icon: string;
  path: string;
}

// Define the props type
interface UidInfoButtonsProps {
  userSubscriptionId: string; // The only prop required is the UID
}

// Define the state type
interface UidInfoButtonsState {
  isOpen: boolean;
  menuItems: MenuItem[]; // Now menuItems are part of the state
}

export class UserSubscriptionButtons extends Component<UidInfoButtonsProps, UidInfoButtonsState> {
  dropdownRef: RefObject<HTMLDivElement>;

  constructor(props: UidInfoButtonsProps) {
    super(props);

    // Initialize state with isOpen and menuItems (with paths for links)
    this.state = {
      isOpen: false,
      menuItems: [
        { label: "Progress", icon: "mdi mdi-book-account", path: `/subscriptions/${props.userSubscriptionId}/progress` },
        { label: "Edit", icon: "mdi mdi-book-account", path: `/subscriptions/${props.userSubscriptionId}/edit` },
        { label: "Payouts", icon: "mdi mdi-book-account", path: `/payouts?userSubscriptionId=${props.userSubscriptionId}` },
        // { label: "Spot Balance", icon: "mdi mdi-cash-100", path: `/platform/users/spot-balance?userSubscriptionId=${props.userSubscriptionId}` },
        // { label: "Future Balance", icon: "mdi mdi-cash-multiple", path: `/platform/users/future-balance?userSubscriptionId=${props.userSubscriptionId}` },
        // { label: "Exchange partner", icon: "mdi mdi-book-account", path: `/exchange-partners/list?userSubscriptionId=${props.userSubscriptionId}` },
        // { label: "Invitations", icon: "mdi mdi-account-group-outline", path: `/platform/users?invitedUid=${props.userSubscriptionId}` }
      ]
    };

    // Create ref to manage outside clicks
    this.dropdownRef = createRef();

    // Bind event handlers
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.handleToggleDropdown = this.handleToggleDropdown.bind(this);
  }

  componentDidMount() {
    // Add event listener for detecting outside clicks
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentWillUnmount() {
    // Remove the event listener
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick(event: MouseEvent) {
    // Close the dropdown if clicked outside
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target as Node)) {
      this.setState({ isOpen: false });
    }
  }

  handleToggleDropdown() {
    // Toggle dropdown open/closed state
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }));
  }

  render() {
    const { userSubscriptionId } = this.props;
    const { isOpen, menuItems } = this.state;

    return (
      <React.Fragment>
        {userSubscriptionId && userSubscriptionId != "0" ? (
          <div className="userSubscriptionId-dropdown" ref={this.dropdownRef} style={{ position: "relative", display: "inline-block" }}>
            <span onClick={this.handleToggleDropdown} style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}>
              {userSubscriptionId}
            </span>

            {isOpen && (
              <ul className="list-unstyled hstack gap-1 mb-1">
                {menuItems.map((item, index) => (
                  <li key={index} data-bs-toggle="tooltip" data-bs-placement="top" title={item.label}>
                    <a className="btn btn-sm btn-soft-primary" id="viewtooltip-1" href={item.path}>
                      <i className={item.icon} />
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ) : (
          <span>{userSubscriptionId}</span>
        )}
      </React.Fragment>
    );
  }
}
