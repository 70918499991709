import React from "react";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import TableContainer from "../../components/common/TableContainer";
import { UserSubscriptionButtons } from "../../components/controls/UserSubscriptionButtons";
import LoaderComponent from "../../components/LoaderComponent";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { SubscriptionPayoutStatus } from "../../lib/drivers/dto/enums/SubscriptionPayoutStatus";
import { SubscriptionStatus } from "../../lib/drivers/dto/enums/SubscriptionStatus";
import { UserSubscriptionPayoutDto } from "../../lib/drivers/dto/subscriptions/UserSubscriptionPayoutDto";
import withRouterHook from "../../withRouterHook";

export type PayoutsPageProps = {
  location?: ReturnType<typeof useLocation>;
};

type Filter = {
  userSubscriptionId?: string;
  userSubscriptionUserId?: string;
  fundTradingProgramId?: string;
  userSubscriptionStatus?: SubscriptionStatus;

  page: number;
  pageSize: number;
  total: number;
};

type PayoutsPageState = {
  isLoading: boolean;
  list: UserSubscriptionPayoutDto[];

  // filter
  filter: Filter;
};

class PayoutsPage extends React.Component<PayoutsPageProps, PayoutsPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: PayoutsPageState = {
    isLoading: true,
    list: [],

    // filter
    filter: {
      page: 1,
      pageSize: 128,
      total: 0
    }
  };

  async componentDidMount() {
    try {
      const queryParams = new URLSearchParams(this.props.location.search);

      const filter: Filter = {
        userSubscriptionId: queryParams.get("userSubscriptionId") || "",
        userSubscriptionUserId: queryParams.get("userSubscriptionUserId") || "",
        fundTradingProgramId: queryParams.get("fundTradingProgramId") || "",
        userSubscriptionStatus: (queryParams.get("status") as SubscriptionStatus) || null,
  
        page: parseInt(queryParams.get("page") || "1", 10),
        pageSize: parseInt(queryParams.get("pageSize") || "128", 10),
        total: 0
      };

      const offset = (filter.page - 1) * filter.pageSize;

      const response = await PortCentralServer.Repo.client.filterUserSubscriptionPayouts({
        userSubscriptionId : filter.userSubscriptionId,
        userSubscriptionUserId : filter.userSubscriptionUserId,
        fundTradingProgramId : filter.fundTradingProgramId,
        userSubscriptionStatus : filter.userSubscriptionStatus,
        limit: filter.pageSize,
        offset: offset
      });

      this.setState({ list: response.data });
    } catch (error) {
      console.error("Error fetching payout data:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  getStatusClass(status: string) {
    switch (status) {
      case SubscriptionPayoutStatus.PENDING:
        return "text-warning";
      case SubscriptionPayoutStatus.PROCESSING:
        return "text-primary";
      case SubscriptionPayoutStatus.COMPLETED:
        return "text-success";
      case SubscriptionPayoutStatus.UNDER_REVIEW:
        return "text-danger";
      case SubscriptionPayoutStatus.NOT_SET:
      default:
        return "text-muted";
    }
  }

  render() {
    const columns = [
      {
        Header: "Subscription",
        accessor: "userSubscriptionId",
        Cell: ({ value }: { value: string }) => {
          if (value && value.length > 0) {
            return <UserSubscriptionButtons userSubscriptionId={value} />;
          }

          return null;
        }
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }: { value: string }) => <span className={this.getStatusClass(value)}>{value || "Unknown Status"}</span>
      },
      { Header: "Network", accessor: "network" },
      { Header: "Address", accessor: "address" },
      {
        Header: "Payout Amount (USDT)",
        accessor: "payoutAmount",
        Cell: ({ value }: { value: number }) => value
      },
      { Header: "Date of Payment", accessor: "datePayment" }
    ];

    return (
      <Layout>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Earns" breadcrumbItem="History" />
            <Row>
              <Col xl="12">
                {this.state.isLoading ? (
                  <LoaderComponent />
                ) : (
                  <Card>
                    <CardBody>
                      <div className="mb-4 h4 card-title">Payout History</div>
                      <TableContainer columns={columns} data={this.state.list} isGlobalFilter={false} isAddOptions={false} customPageSize={10} />
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouterHook(PayoutsPage);
