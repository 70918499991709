import { CentralServerPathParamKind, CentralServerQueryParamKind } from "../../drivers/CentralServerClientEnums";

export class UrlParamPair {
    // #region Private fields
    private readonly _key: string;
    private readonly _value: string;
    // #endregion

    // #region Properties
    public get key(): string {
        return this._key;
    }

    public get value(): string {
        return this._value;
    }
    // #endregion

    // #region Constructor
    constructor(key: string, value: string) {
        this._key = key;
        this._value = value;
    }
    // #endregion
}

export class PathParamPair extends UrlParamPair {
    constructor(key: CentralServerPathParamKind, value: string) {
        super(key.toString(), value);
    }
}

export class QueryParamPair extends UrlParamPair {
    constructor(key: CentralServerQueryParamKind, value: string) {
        super(key.toString(), value);
    }
}
