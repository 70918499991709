import React from "react";
import { Col, Row } from "reactstrap";
import { BaseModal } from "../../../components/modals/BaseModal";
import { SubscriptionPayoutStatus } from "../../../lib/drivers/dto/enums/SubscriptionPayoutStatus";
import { UserSubscriptionPayoutDto } from "../../../lib/drivers/dto/subscriptions/UserSubscriptionPayoutDto";

export type ModalUserSubscriptionPayoutCompleteArgs = {
  comment: string;
};

export type ModalUserSubscriptionPayoutCompleteProps = {
  entity: UserSubscriptionPayoutDto;
  onCloseClick(): void;
  onSubmitClick(args: ModalUserSubscriptionPayoutCompleteArgs): void;
};

type ModalUserSubscriptionPayoutCompleteState = {
  here: boolean;
};

export class ModalUserSubscriptionPayoutComplete extends React.Component<ModalUserSubscriptionPayoutCompleteProps, ModalUserSubscriptionPayoutCompleteState> {
  state: ModalUserSubscriptionPayoutCompleteState = {
    here: true
  };

  refComment: React.RefObject<HTMLTextAreaElement>;

  constructor(props: ModalUserSubscriptionPayoutCompleteProps) {
    super(props);

    this.refComment = React.createRef();
  }

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    this.setState((prevState) => {
      return { here: true };
    });
  };

  componentDidUpdate = async (prevProps: ModalUserSubscriptionPayoutCompleteProps, prevState: ModalUserSubscriptionPayoutCompleteState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  onCopyTextClick = async (e: React.FormEvent<HTMLButtonElement>, text: string) => {
    e.preventDefault();

    if (navigator.clipboard && navigator.clipboard.writeText) {
      try {
        await navigator.clipboard.writeText(text);
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    } else {
      // Fallback for older browsers
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }
      document.body.removeChild(textarea);
    }
  };

  onCloseClick = () => {
    if (this.props.onCloseClick) {
      this.props.onCloseClick();
    }
  };

  onSubmitClick = () => {
    const comment: string = this.refComment.current ? this.refComment.current.value : "";

    const args: ModalUserSubscriptionPayoutCompleteArgs = {
      comment: comment
    };

    if (this.props.onSubmitClick) {
      this.props.onSubmitClick(args);
    }
  };
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <BaseModal
          isOpen={true}
          size="lg"
          title="Reject Purchase"
          onCloseClick={this.onCloseClick}
          onSubmitClick={this.onSubmitClick}
          content={
            <>
              <Row className="mb-4">
                <label className="col-sm-3 col-form-label">Comment</label>
                <Col sm={9}>
                  <textarea ref={this.refComment} className="form-control" placeholder="Comment" rows={4} maxLength={1000} />
                </Col>
              </Row>

              {this.props.entity.status == SubscriptionPayoutStatus.PENDING && (
                <>
                  <hr />
                  <Row>
                    <Col>
                      <h5>Payout network</h5>
                      <p>
                        {this.props.entity.network ? (
                          <>
                            <span>{this.props.entity.network}</span>
                            <button type="button" className="btn btn-sm mx-1" onClick={(e) => this.onCopyTextClick(e, this.props.entity.network)}>
                              <i className="bx bx-copy" />
                            </button>
                          </>
                        ) : (
                          <span className="text-danger">
                            <i className="bx bx-error"></i> not provided
                          </span>
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h5>Payout address</h5>
                      <p>
                        {this.props.entity.address ? (
                          <>
                            <span>{this.props.entity.address}</span>
                            <button type="button" className="btn btn-sm mx-1" onClick={(e) => this.onCopyTextClick(e, this.props.entity.address)}>
                              <i className="bx bx-copy" />
                            </button>
                          </>
                        ) : (
                          <span className="text-danger">
                            <i className="bx bx-error"></i> not provided
                          </span>
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h5>Payout amount</h5>
                      <p>
                        {this.props.entity.payoutAmount} USDT
                        <button type="button" className="btn btn-sm mx-1" onClick={(e) => this.onCopyTextClick(e, this.props.entity.payoutAmount.toString())}>
                          <i className="bx bx-copy" />
                        </button>
                      </p>
                    </Col>
                  </Row>
                </>
              )}
            </>
          }
        />
      </React.Fragment>
    );
  }
}
