import "flatpickr/dist/themes/material_blue.css";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, CardBody, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import LoaderComponent from "../../components/LoaderComponent";
import TableContainer from "../../components/common/TableContainer";
import StatusBadge from "../../components/controls/StatusBadge";
import { SubscriptionIndexFlags } from "../../components/controls/SubscriptionIndexFlags";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { SubscriptionIndexFlagType } from "../../lib/drivers/dto/enums/SubscriptionIndexFlagType";
import { SubscriptionPayoutStatus } from "../../lib/drivers/dto/enums/SubscriptionPayoutStatus";
import { SubscriptionStatus } from "../../lib/drivers/dto/enums/SubscriptionStatus";
import { ViewUserSubscriptionProgressInfoDto } from "../../lib/drivers/dto/subscriptions/ViewUserSubscriptionProgressInfoDto";
import withRouterHook from "../../withRouterHook";

export type SubscriptionsPageProps = {
  location?: ReturnType<typeof useLocation>;
};

type SubscriptionsPageState = {
  isLoading: boolean;
  tableData: ViewUserSubscriptionProgressInfoDto[] | null;
  filter: {
    userSubscriptionId?: string;
    userSubscriptionUserId?: string;
    fundTradingProgramId?: string;
    userSubscriptionStatus?: SubscriptionStatus;
  };
};

class SubscriptionsPage extends React.Component<SubscriptionsPageProps, SubscriptionsPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: SubscriptionsPageState = {
    isLoading: true,
    tableData: null,
    filter: {
      userSubscriptionId: "",
      userSubscriptionUserId: "",
      fundTradingProgramId: "",
      userSubscriptionStatus: SubscriptionStatus.ALL
    }
  };

  async componentDidMount() {
    await this.fetchSubscriptions();
  }

  // Fetch subscriptions based on filters
  fetchSubscriptions = async () => {
    this.setState({ isLoading: true });
    try {
      const filter = this.state.filter;

      if (filter.userSubscriptionStatus === SubscriptionStatus.ALL) {
        delete filter.userSubscriptionStatus;
      }

      const userSubscriptions = await PortCentralServer.Repo.client.filterViewUserSubscriptionProgressInfos(filter);

      const sortedSubscriptions = userSubscriptions.data.sort((a, b) => {
        const statusOrder = ["DRAFT", "PAID", "READY", "ACTIVE", "FINISHED", "FAILED"];
        return statusOrder.indexOf(a.userSubscriptionStatus) - statusOrder.indexOf(b.userSubscriptionStatus);
      });

      this.setState({ tableData: sortedSubscriptions });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  // Handle form input change
  handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      filter: { ...prevState.filter, [name]: value }
    }));
  };

  handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    this.fetchSubscriptions();
  };

  render() {
    const { filter: filters } = this.state;

    const columns = [
      {
        Header: "Details",
        Cell: ({ row }: { row: any }) => (
          <React.Fragment>
            {/* <Link to={`/subscriptions/${row.original.userSubscriptionId}/details`} className="btn btn-primary me-2">
              Details
            </Link> */}

            <Link to={`/subscriptions/${row.original.userSubscriptionId}/edit`} className="btn btn-primary me-2">
              Edit
            </Link>

            <Link to={`/subscriptions/${row.original.userSubscriptionId}/progress`} className="btn btn-info me-2">
              Progress
            </Link>
          </React.Fragment>
        )
      },
      { Header: "Subscription ID", accessor: "userSubscriptionId" },
      {
        Header: "Status",
        accessor: "userSubscriptionStatus",
        Cell: ({ value }: { value: SubscriptionPayoutStatus }) => <StatusBadge status={value} />
      },

      { Header: "Fund Trading Program Title", accessor: "fundTradingProgramTitle" },
      { Header: "Stages", accessor: "fundTradingProgramNumberStages" },
      { Header: "Account Size", accessor: "fundTradingProgramAccountSize" },
      {
        Header: "Current Stage",
        Cell: ({ row }: { row: any }) => {
          if (row.original.fundTradingProgramStageSequence === null) {
            return "No Stage";
          }
          const incrementedValue = row.original.fundTradingProgramStageSequence + 1; // Increment the value

          if (incrementedValue >= row.original.fundTradingProgramNumberStages + 1) {
            return "Live";
          }

          return `Stage ${incrementedValue}`;
        }
      },
      { Header: "User Email", accessor: "userEmail" },
      {
        Header: "Date Created",
        accessor: "userSubscriptionDateCreated",
        Cell: ({ value }: { value: string }) => `${new Date(value).toLocaleDateString()} ${new Date(value).toLocaleTimeString()}`
      },
      {
        Header: "Current Profit",
        accessor: "currentStageIndexCurrentProfitPercentage",
        Cell: ({ value }: { value: number }) => `${Number(value).toFixed(2)} %`
      },
      {
        Header: "Flags",
        accessor: "currentStageIndexFlags",
        Cell: ({ value }: { value: SubscriptionIndexFlagType[] | null }) => <SubscriptionIndexFlags flags={value} />
      }
    ];

    return (
      <Layout>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Subscriptions" breadcrumbItem="List" />

            <Form onSubmit={this.handleSubmit}>
              <Row className="justify-content-center">
                <Col md={2}>
                  <FormGroup>
                    <Label for="userSubscriptionId">Subscription ID</Label>
                    <Input type="text" name="userSubscriptionId" value={filters.userSubscriptionId || ""} onChange={this.handleInputChange} />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label for="userId">User ID</Label>
                    <Input type="text" name="userSubscriptionUserId" value={filters.userSubscriptionUserId || ""} onChange={this.handleInputChange} />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label for="fundTradingProgramId">Fund Trading Program ID</Label>
                    <Input type="text" name="fundTradingProgramId" value={filters.fundTradingProgramId || ""} onChange={this.handleInputChange} />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label for="userSubscriptionStatus">Status</Label>
                    <Input type="select" name="userSubscriptionStatus" defaultValue={filters.userSubscriptionStatus || ""} onChange={this.handleInputChange}>
                      {Object.values(SubscriptionStatus).map((userSubscriptionStatus) => (
                        <option key={userSubscriptionStatus} value={userSubscriptionStatus}>
                          {userSubscriptionStatus}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md={2}>
                  <Button color="primary" type="submit" block>
                    Apply Filters
                  </Button>
                </Col>
              </Row>
            </Form>

            <br />

            <Row>
              <Col xl="12">
                {this.state.isLoading ? (
                  <LoaderComponent />
                ) : (
                  <Row>
                    <CardBody>
                      <div className="mb-4 h4 card-title">User Subscriptions</div>
                      <TableContainer
                        columns={columns}
                        data={this.state.tableData || []} // Ensure to handle null data
                        isGlobalFilter={false}
                        isAddOptions={false}
                        customPageSize={20}
                      />
                    </CardBody>
                  </Row>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouterHook(SubscriptionsPage);
