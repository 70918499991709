import React from "react";

type FormattedNumberProps = {
  value: string | number | bigint;
  highlight?: boolean; // Optional prop to indicate if color styling should be applied
};

class UniversalNumberFormatter extends React.Component<FormattedNumberProps> {
  formatValue(value: string | number | bigint): string {
    let numberValue: number;

    // Handle different types
    if (typeof value === "string") {
      numberValue = parseFloat(value);
      if (isNaN(numberValue)) return value; // Return the original string if it's not a valid number
    } else if (typeof value === "bigint") {
      // Convert bigint to number safely, unless it's out of range for number
      numberValue = Number(value);
    } else {
      numberValue = value;
    }

    // Format number with 2 decimal places
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 8,
    }).format(numberValue);
  }

  render() {
    const { value, highlight = true } = this.props; // Default highlight to true if not provided
    let numericValue: number | null = null;

    if (typeof value === "string" || typeof value === "number" || typeof value === "bigint") {
      numericValue = typeof value === "string" ? parseFloat(value) : Number(value);
    }

    // Apply CSS classes based on the numeric value if valid and highlight is enabled
    const textClass = highlight
      ? numericValue !== null && numericValue < 0
        ? "text-danger"
        : numericValue !== null && numericValue > 0
        ? "text-success"
        : ""
      : "";

    return <span className={textClass}>{this.formatValue(value)}</span>;
  }
}

export default UniversalNumberFormatter;
