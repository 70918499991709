import { CentralServerClient } from "../../drivers/CentralServerClient";

export class PortCentralServer {
    // #region Private fields
    private readonly _client: CentralServerClient;

    // #endregion

    // #region Properties
    public get client(): CentralServerClient {
        return this._client;
    }
    // #endregion

    // #region Static
    public static Repo: PortCentralServer = new PortCentralServer();
    // #endregion

    // #region Constructor
    constructor() {
        this._client = new CentralServerClient();
    }
    // #endregion

    // #region Public Functions
    // #endregion

    // #region Private Functions
    // #endregion
}