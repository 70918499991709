import React from "react";
import { Col, Row } from "reactstrap";
import { EnumBadgeControl } from "../../../components/controls/EnumBadgeControl";
import { ViewUserSubscriptionProgressInfoDto } from "../../../lib/drivers/dto/subscriptions/ViewUserSubscriptionProgressInfoDto";
import { UserInfoButtons } from "../../../components/controls/UserInfoButtons";

export type UserSubscriptionItemComponentProps = {
  entity: ViewUserSubscriptionProgressInfoDto;
};

type UserSubscriptionItemComponentState = {};

export class UserSubscriptionItemComponent extends React.Component<UserSubscriptionItemComponentProps, UserSubscriptionItemComponentState> {
  state: UserSubscriptionItemComponentState = {};

  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: UserSubscriptionItemComponentProps, prevState: UserSubscriptionItemComponentState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-body">
            <Row>
              <Col>
                <h4>
                  {this.props.entity.fundTradingProgramTitle}: ${Number(this.props.entity.fundTradingProgramAccountSize).toLocaleString()}
                </h4>
              </Col>
            </Row>
            <hr />
            <Row className="mt-4">
              <Col>
                <h5>User Id</h5>
                <p>
                  <UserInfoButtons userId={this.props.entity.userSubscriptionUserId} />
                </p>
              </Col>
              <Col>
                <h5>Email</h5>
                <p>{this.props.entity.userEmail}</p>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                <h5>Status</h5>
                <div>
                  <EnumBadgeControl enumType="SubscriptionStatus" value={this.props.entity.userSubscriptionStatus} />
                </div>
              </Col>
              <Col>
                <h5>Price</h5>
                <p>{Number(this.props.entity.fundTradingProgramPrice).toLocaleString()} USDT</p>
              </Col>

              <Col>
                <h5>Stages</h5>
                <p>
                  {this.props.entity.fundTradingProgramStageSequence + 1} / {this.props.entity.fundTradingProgramNumberStages + 1}
                </p>
              </Col>

              <Col>
                <h5>Current Stage</h5>
                <p>{this.props.entity.fundTradingProgramStageType}</p>
              </Col>

              <Col>
                <h5>Payout type</h5>
                <p>{this.props.entity.userSubscriptionPayoutType}</p>
              </Col>

              {/* 
              userSubscriptionDateStart
              userSubscriptionProfitSplit

              fundTradingProgramNumberStages
              fundTradingProgramPrice

              fundTradingProgramStageSequence
              fundTradingProgramStageType
              fundTradingProgramStageProfitSplit
              fundTradingProgramStageMaxTotalLoss   
              fundTradingProgramStageMaxDailyLoss   
              fundTradingProgramStageMaxTrailingLoss
              fundTradingProgramStageProfitTarget   
              fundTradingProgramStageMinTradingDays 

              currentStageIndexStatus
              currentStageIndexDateFrom
              currentStageIndexTotalOrders
              currentStageIndexTradingVolume
              currentStageIndexTotalRealizedPnl
              currentStageIndexCurrentProfitPercentage
              currentStageIndexAllowProfitClaimYn
              currentStageIndexProfitSplitAmount
              currentStageIndexFlags 
              */}
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
