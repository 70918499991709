import { CentralServerEndpointKind } from "../../drivers/CentralServerClientEnums";
import { UrlParamPair } from "./UrlParamPair";

export class ServerEndpointBuilder {
    // #region Private fields
    private readonly _baseUrl: string = "";
    private readonly _apiPrefix: string = "";
    // #endregion

    // #region Properties
    public get baseUrl(): string {
        return this._baseUrl;
    }

    public get apiPrefix(): string {
        return this._apiPrefix;
    }
    // #endregion

    // #region Constructor
    constructor(baseUrl: string, apiPrefix: string = "") {
        this._baseUrl = baseUrl;
        this._apiPrefix = apiPrefix;
    }
    // #endregion

    // #region Public Functions
    public buildUrl(endpointKind: string, pathParams: UrlParamPair[] | null, queryParams: UrlParamPair[] | null): string {
        let result: string = this._baseUrl;

        if (this._apiPrefix.length > 0) {
            result += `/${this._apiPrefix}`;
        }

        let endpoint = endpointKind.toString();

        if (pathParams && pathParams.length > 0) {
            for (const pair of pathParams) {
                endpoint = endpoint.replace(pair.key, pair.value);
            }
        }

        result += `/${endpoint}`;

        if (queryParams && queryParams.length > 0) {
            let first: boolean = true;
            for (const pair of queryParams) {
                if (first) {
                    result += "?";
                }
                else {
                    result += "&";
                }

                result += `${pair.key}=${pair.value}`;

                first = false;
            }
        }


        return encodeURI(result);
    }
    // #endregion

    // #region Private Functions
    // #endregion
}

