import moment from "moment";
import React from "react";
import { Col, Row } from "reactstrap";
import { UserSubscriptionStageIndexDto } from "../../../lib/drivers/dto/subscriptions/UserSubscriptionStageIndexDto";
import FormattedNumber from "../../../components/controls/FormattedNumber";

export type UserSubscriptionStageIndexItemComponentProps = {
  entity: UserSubscriptionStageIndexDto;
};

type UserSubscriptionStageIndexItemComponentState = {};

export class UserSubscriptionStageIndexItemComponent extends React.Component<UserSubscriptionStageIndexItemComponentProps, UserSubscriptionStageIndexItemComponentState> {
  state: UserSubscriptionStageIndexItemComponentState = {};

  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: UserSubscriptionStageIndexItemComponentProps, prevState: UserSubscriptionStageIndexItemComponentState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-body">
            <Row className="mt-4">
              <Col><h5>Status</h5><p>{this.props.entity.status}</p></Col>
              <Col><h5>Date created</h5><p>{moment(this.props.entity.dateCreated).format("YYYY-MM-DD HH:mm:ss")}</p></Col>
              <Col><h5>Date synced</h5><p>{moment(this.props.entity.dateSynced).format("YYYY-MM-DD HH:mm:ss")}</p></Col>
              <Col><h5>Date from</h5><p>{this.props.entity.dateFrom ? moment(this.props.entity.dateFrom).format("YYYY-MM-DD HH:mm:ss") : "-"}</p></Col>
              <Col><h5>Date to</h5><p>{this.props.entity.dateTo ? moment(this.props.entity.dateTo).format("YYYY-MM-DD HH:mm:ss") : "-"}</p></Col>
            </Row>
            <Row className="mt-4">
              <Col><h5>Total orders</h5><p>{this.props.entity.totalOrders}</p></Col>
              <Col><h5>Trading volume</h5><p><FormattedNumber value={this.props.entity.tradingVolume} /></p></Col>
              <Col><h5>Total realized PNL</h5><p><FormattedNumber value={this.props.entity.totalRealizedPnl} /></p></Col>
              <Col><h5>Current profit percentage</h5><p><FormattedNumber value={this.props.entity.currentProfitPercentage} /> %</p></Col>
              <Col><h5>Allow profit claim</h5><p>{this.props.entity.allowProfitClaimYn ? "Yes" : "No"}</p></Col>
            </Row>
            <Row className="mt-4">
              <Col><h5>Profit split amount</h5><p><FormattedNumber value={this.props.entity.profitSplitAmount} /></p></Col>
              <Col><h5>Stage sequence</h5><p>{this.props.entity.stageSequence + 1}</p></Col>
              <Col><h5>Stage type</h5><p>{this.props.entity.stageType}</p></Col>
              <Col><h5>Message</h5><p className="text-warning">{this.props.entity.message}</p></Col>
              <Col></Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}