import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectToListener: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleRedirect = (event: CustomEvent) => {
      // Redirect to the specified route
      navigate(event.detail);
    };

    // Add event listener
    document.addEventListener('redirectEvent', handleRedirect as EventListener);

    // Cleanup
    return () => {
      document.removeEventListener('redirectEvent', handleRedirect as EventListener);
    };
  }, [navigate]);

  return null;
};

export default RedirectToListener;
