import React from "react";
import { BaseModal } from "../../../components/modals/BaseModal";
import { TradingPlatformKind } from "../../../lib/drivers/dto/enums/TradingPlatformKind";

export type ModalUserSubscriptionActivateArgs = {
  platformKind: TradingPlatformKind;
  uid: string;
  username: string;
  password: string;
  mfa: string;
};

export type ModalUserSubscriptionActivateProps = {
  userSubscriptionId: string;
  onCloseClick(): void;
  onSubmitClick(args: ModalUserSubscriptionActivateArgs): void;
};

type ModalUserSubscriptionActivateState = {};

export class ModalUserSubscriptionActivate extends React.Component<ModalUserSubscriptionActivateProps, ModalUserSubscriptionActivateState> {
  state: ModalUserSubscriptionActivateState = {};

  refPlatformKind: React.RefObject<HTMLSelectElement>;
  refUid: React.RefObject<HTMLInputElement>;
  refUsername: React.RefObject<HTMLInputElement>;
  refPassword: React.RefObject<HTMLInputElement>;
  refMfa: React.RefObject<HTMLInputElement>;

  constructor(props: ModalUserSubscriptionActivateProps) {
    super(props);

    this.refPlatformKind = React.createRef();
    this.refUid = React.createRef();
    this.refUsername = React.createRef();
    this.refPassword = React.createRef();
    this.refMfa = React.createRef();
  }

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    this.setState((prevState) => {
      return { here: true };
    });
  };

  componentDidUpdate = async (prevProps: ModalUserSubscriptionActivateProps, prevState: ModalUserSubscriptionActivateState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  onCloseClick = () => {
    if (this.props.onCloseClick) {
      this.props.onCloseClick();
    }
  };

  onSubmitClick = () => {
    const platformKind: string = this.refPlatformKind.current ? this.refPlatformKind.current.value : "";
    const userId: string = this.refUid.current ? this.refUid.current.value : "";
    const username: string = this.refUsername.current ? this.refUsername.current.value : "";
    const password: string = this.refPassword.current ? this.refPassword.current.value : "";
    const mfa: string = this.refMfa.current ? this.refMfa.current.value : "";

    const args: ModalUserSubscriptionActivateArgs = {
      platformKind: platformKind as TradingPlatformKind,
      uid: userId,
      username: username,
      password: password,
      mfa: mfa
    };

    if (this.props.onSubmitClick) {
      this.props.onSubmitClick(args);
    }
  };
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <BaseModal
          isOpen={true}
          content={
            <>
              <div className="align-items-center text-center">
                <div className="mt-3">
                  <h5>Credentials:</h5>
                  <div className="mb-2">
                    <label>Platform Kind:</label>
                    <select className="form-control" ref={this.refPlatformKind}>
                      <option value={TradingPlatformKind.BONEX_MARGIN}>BONEX_MARGIN</option>
                      <option value={TradingPlatformKind.NOT_SET}>NOT_SET</option>
                      {/* Add other platform kinds if needed */}
                    </select>
                  </div>
                  <div className="mb-2">
                    <label>UID:</label>
                    <input type="text" className="form-control" ref={this.refUid} />
                  </div>
                  <div className="mb-2">
                    <label>Username:</label>
                    <input type="text" className="form-control" ref={this.refUsername} />
                  </div>
                  <div className="mb-2">
                    <label>Password:</label>
                    <input type="password" className="form-control" ref={this.refPassword} />
                  </div>
                  <div className="mb-2">
                    <label>MFA:</label>
                    <input type="text" className="form-control" ref={this.refMfa} />
                  </div>
                </div>
              </div>
            </>
          }
          title="Confirm Subscription Deactivation"
          onSubmitClick={this.onSubmitClick}
          onCloseClick={this.onCloseClick}
        />
      </React.Fragment>
    );
  }
}
