import moment from "moment";
import React from "react";
import { Col, Row } from "reactstrap";
import { UserAffiliateDto } from "../../../lib/drivers/dto/affiliate/UserAffiliateDto";

export type UserAffiliateItemComponentProps = {
  entity: UserAffiliateDto;
  totalPayoutAmount: number;
  totalPendingPayoutAmount: number;
};

type UserAffiliateItemComponentState = {};

export class UserAffiliateItemComponent extends React.Component<UserAffiliateItemComponentProps, UserAffiliateItemComponentState> {
  state: UserAffiliateItemComponentState = {};

  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: UserAffiliateItemComponentProps, prevState: UserAffiliateItemComponentState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-body">
            <Row className="mt-4">
              <Col>
                <h5>User Id</h5>
                <p>{this.props.entity.userId}</p>
              </Col>
              <Col>
                <h5>Email</h5>
                <p>{this.props.entity.email}</p>
              </Col>
              <Col>
                <h5>Username</h5>
                <p>{this.props.entity.username}</p>
              </Col>
              <Col>
                <h5>Name</h5>
                <p>
                  {this.props.entity.firstName} {this.props.entity.lastName}
                </p>
              </Col>
              <Col>
                <h5>Invitation code</h5>
                <p>{this.props.entity.invitationCode}</p>
              </Col>
              <Col>
                <h5>Active</h5>
                <p>{`${this.props.entity.activeYn}`}</p>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <div>
                  <h5>
                    <i className="bx bx-calendar"></i> Date created
                  </h5>
                  <p>{moment(this.props.entity.dateCreated).format("YYYY-MM-DD HH:mm:ss")}</p>
                </div>
              </Col>
              <Col>
                <h5>Purchase share percentages</h5>
                <p>{this.props.entity.purchaseSharePercentages} %</p>
              </Col>
              <Col>
                <h5>Discount percentages</h5>
                <p>{this.props.entity.discountPercentages} %</p>
              </Col>
              <Col>
                <h5>Payout network</h5>
                <p>{this.props.entity.payoutNetwork ? <>{this.props.entity.payoutNetwork}</> : <span className="text-danger"><i className="bx bx-error"></i> not provided</span>}</p>
              </Col>
              <Col>
                <h5>Payout address</h5>
                <p>{this.props.entity.payoutAddress ? <>{this.props.entity.payoutAddress}</> : <span className="text-danger"><i className="bx bx-error"></i> not provided</span>}</p>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <h5>Total paid</h5>
                <p className="text-danger">
                  <strong>{this.props.totalPayoutAmount} USDT</strong>
                </p>
              </Col>
              <Col>
                <h5>Total pending</h5>
                <p className="text-warning">
                  <strong>{this.props.totalPendingPayoutAmount} USDT</strong>
                </p>
              </Col>
              <Col></Col>
              <Col></Col>
              <Col></Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
