import React from "react";
import { Link } from "react-router-dom";
import { SidebarContent } from "./SidebarContent";

import logo from "../assets/images/logo-light.svg";

// Props & State
export type SidebarProps = {
  theme: string;
  type: string;
  isMobile: boolean;
};

type SidebarState = {};

export class Sidebar extends React.Component<SidebarProps, SidebarState> {
  render() {
    return (
      <React.Fragment>
        <div className="vertical-menu">
          <div className="navbar-brand-box">
            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={logo} alt="BoneX Fund" height="23" />
              </span>
              <span className="logo-lg">
                <img src={logo} alt="BoneX Fund" height="23" />
                <span>&nbsp;BoneX Fund</span>
              </span>
            </Link>
          </div>
          <div data-simplebar className="h-100">
            <SidebarContent />
          </div>
          <div className="sidebar-background"></div>
        </div>
      </React.Fragment>
    );
  }
}
