import moment from "moment";
import React from "react";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import FormattedNumber from "../../../components/controls/FormattedNumber";
import { FilterFuturePositionItemResponseDto } from "../../../lib/drivers/dto/central/FilterFuturePositionItemResponseDto";
import UniversalNumberFormatter from "../../../components/controls/UniversalNumberFormatter";

export type CentralFuturePositionListComponentProps = {
  list: FilterFuturePositionItemResponseDto[];
};

type CentralFuturePositionListComponentState = {};

export class CentralFuturePositionListComponent extends React.Component<CentralFuturePositionListComponentProps, CentralFuturePositionListComponentState> {
  state: CentralFuturePositionListComponentState = {};

  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: CentralFuturePositionListComponentProps, prevState: CentralFuturePositionListComponentState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <h5 className="fw-semibold">Payouts</h5>
            <Row>
              <Col xl={12}>
                <div className="table-responsive">
                  <Table className="table table-bordered m-0">
                    <thead>
                      <tr>
                        <th>id</th>
                        <th>ctimeStamp</th>
                        <th>originUid</th>
                        <th>contractName</th>
                        <th>side</th>
                        <th>positionType</th>
                        <th>positionBalance</th>
                        <th>holdAmount</th>
                        <th>status</th>
                        <th>openRealizedAmount</th>
                        <th>leverageLevel</th>
                        <th>volume</th>
                        <th>unRealizedAmount</th>
                        <th>tradeFee</th>
                        <th>realizedAmount</th>
                        <th>openPrice</th>
                        <th>closePrice</th>
                        <th>avgPrice</th>
                        <th>positionValue </th>
                        <th>closeProfit</th>
                        <th>buy</th>
                        <th>closeVolume</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(this.props.list || []).map((item: FilterFuturePositionItemResponseDto, key: number) => {
                        return (
                          <tr key={key}>
                            <td>{`${item.id}`}</td>
                            <td>{moment(Number(item.ctimeStamp)).format("YYYY-MM-DD HH:mm:ss")}</td>
                            <td>{`${item.originUid}`}</td>
                            <td>{item.contractName}</td>
                            <td>{item.side}</td>
                            <td>{item.positionType}</td>
                            <td>
                              <UniversalNumberFormatter value={item.positionBalance} />
                            </td>
                            <td>
                              <UniversalNumberFormatter value={item.holdAmount} />
                            </td>
                            <td>{item.status}</td>
                            <td>
                              <UniversalNumberFormatter value={item.openRealizedAmount} />
                            </td>
                            <td>{item.leverageLevel}</td>
                            <td>{item.volume}</td>
                            <td>
                              <UniversalNumberFormatter value={item.unRealizedAmount} highlight={true} />
                            </td>
                            <td>
                              <UniversalNumberFormatter value={item.tradeFee} highlight={false} />
                            </td>
                            <td>
                              <UniversalNumberFormatter value={item.realizedAmount} highlight={true} />
                            </td>
                            <td>
                              <UniversalNumberFormatter value={item.openPrice} highlight={false} />
                            </td>
                            <td>
                              <UniversalNumberFormatter value={item.closePrice} highlight={false} />
                            </td>
                            <td>
                              <UniversalNumberFormatter value={item.avgPrice} highlight={false} />
                            </td>
                            <td>
                              <UniversalNumberFormatter value={item.positionValue} highlight={false} />
                            </td>
                            <td>{item.closeProfit}</td>
                            <td>{item.buy}</td>
                            <td>{item.closeVolume}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}
