
export enum CentralServerPathParamKind {
    userId = "{userId}",
    year = "{year}",
    month = "{month}",
    fundTradingProgramId = "{fundTradingProgramId}",
    userSubscriptionId = "{userSubscriptionId}",
    userSubscriptionPayoutId = "{userSubscriptionPayoutId}",
    userSubscriptionStageIndexId = "{userSubscriptionStageIndexId}",
    userAffiliateId = "{userAffiliateId}",
    userSubscriptionCredentialId = "{userSubscriptionCredentialId}",
    platformUserUid = "{platformUserUid}"
}

export enum CentralServerQueryParamKind {
    // Unknown = "unknown",
}

export enum CentralServerEndpointKind {
    // auth
    auth_sign_in = "auth/sign-in",
    auth_request_password_reset = "auth/request-password-reset",
    auth_reset_password = "auth/reset-password",
    auth_accept_invitation = "auth/accept-invitation",

    // profile
    profile_change_password = "profile/change-password",
    user_badge = "profile/badge",

    // system
    logs_filter = "logs/filter",
    tasks = "tasks",

    //incoming
    user_subscription_activate = "incoming/subscriptions/set-ready",
    user_subscription_stage_activate = "incoming/subscriptions/indexes/set-ready",
    user_subscription_deactivate = "incoming/subscriptions/set-disabled",

    // user
    users_filter = "users/filter",
    users_invite = "users/invite",
    users_count = "users/count",

    // affiliates
    affiliate_filter = "affiliates/filter",
    affiliate_by_id = "affiliates/{userAffiliateId}",
    affiliates_payouts_filter = "affiliates/payouts/filter",
    affiliates_purchases_shares_filter = "affiliates/purchases/shares/filter",
    affiliates_purchases_shares_approve = "affiliates/purchases/shares/approve",
    affiliates_purchases_shares_reject = "affiliates/purchases/shares/reject",

    // userSubscription
    user_subscriptions_view_filter = "user-subscriptions/infos/filter",
    user_subscriptions_view_by_id = "user-subscriptions/infos/{userSubscriptionId}",
    user_subscriptions_filter = "user-subscriptions/filter",
    user_subscriptions_stages = "user-subscriptions/{userSubscriptionId}/stage-indexes",
    user_subscriptions_stage_index = "user-subscriptions/{userSubscriptionId}/stage-indexes/{userSubscriptionStageIndexId}",
    user_subscriptions_stage_index_days = "user-subscriptions/{userSubscriptionId}/stage-indexes/{userSubscriptionStageIndexId}/days",
    user_subscriptions_stage_index_day_orders = "user-subscriptions/{userSubscriptionId}/stage-indexes/{userSubscriptionStageIndexId}/orders/filter",
    user_subscriptions_credentials_by_id = "user-subscriptions/credentials/{userSubscriptionCredentialId}",
    // user_subscriptions_payouts_by_user = "user-subscriptions/payouts/{userSubscriptionPayoutId}/{userId}",
    user_subscriptions_payouts_complete = "user-subscriptions/payouts/complete",
    user_subscriptions_purchases_filter = "user-subscriptions/purchases/filter",
    user_subscriptions_payouts_filter = "user-subscriptions/payouts/filter",

    // central
    central_platform_user_get_by_id = "central/getPlatformUserById/{platformUserUid}",
    central_future_orders_filter = "central/filterFutureOrders",
    central_future_positions_filter = "central/filterFuturePositions",

    // reporting
    reporting_central_filter_actual_future_position = "reporting/central/filter-actual-future-position"
}
