import React from "react";
import { AppContext } from "../context/AppProvider";
import { Layout } from "../layout";
import withRouterHook from "../withRouterHook";

export type NotificationsPageProps = {};

type NotificationsPageState = {};

class NotificationsPage extends React.Component<NotificationsPageProps, NotificationsPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  componentDidMount = async () => {
    // ...
  };

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <h1>This is NotificationsPage</h1>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(NotificationsPage);
