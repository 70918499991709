import moment from "moment";
import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { CardBody, Col, Row } from "reactstrap";
import ApexRadial from "../../components/common/ApexRadial";
import LoaderComponent from "../../components/LoaderComponent";
import { BaseModal } from "../../components/modals/BaseModal";
import PagerComponent from "../../components/PagerComponent";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { BaseFilter } from "../../lib/core/filters/BaseFilter";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { StageIndexStatus } from "../../lib/drivers/dto/enums/StageIndexStatus";
import { SubscriptionIndexFlagType } from "../../lib/drivers/dto/enums/SubscriptionIndexFlagType";
import { SubscriptionPayoutStatus } from "../../lib/drivers/dto/enums/SubscriptionPayoutStatus";
import { SubscriptionStatus } from "../../lib/drivers/dto/enums/SubscriptionStatus";
import { TradingPlatformKind } from "../../lib/drivers/dto/enums/TradingPlatformKind";
import { ViewUserSubscriptionProgressInfoDto } from "../../lib/drivers/dto/subscriptions/ViewUserSubscriptionProgressInfoDto";
import withRouterHook from "../../withRouterHook";

type SubscriptionDetailsPageParams = ReturnType<typeof useParams> & {
  userSubscriptionId: string;
};

export type SubscriptionDetailsPageProps = {
  location?: ReturnType<typeof useLocation>;
  params?: SubscriptionDetailsPageParams;
};

type Filter = BaseFilter & {
  userSubscriptionId?: string;
};

type Credentials = {
  platformKind: TradingPlatformKind;
  uid: string;
  username: string;
  password: string;
  mfa: string;
};

type AccountDetails = {
  username: string;
  password: string;
  mfa: string;
};

type PayoutDetails = {
  status: SubscriptionPayoutStatus;
  network: string;
  address: string;
  payoutAmount: number;
};

type SubscriptionDetailsPageState = {
  isLoading: boolean;
  list: ViewUserSubscriptionProgressInfoDto[];
  filter: Filter;
  isDeactivateModalOpen: boolean;
  isCredentialsModalOpen: boolean;
  isPayoutModalOpen: boolean;
  isActiveSubscriptionModalOpen: boolean;
  isActiveSubscriptionStageModalOpen: boolean;
  subscriptionToActivate: string;
  subscriptionToActivateStage: string;
  subscriptionStageToActivate: string;
  subscriptionToDeactivate: string;
  accountDetails: AccountDetails;
  payoutDetails: PayoutDetails;
  deactivateMessage: string;
  subscriptionActivationCredentials: Credentials;
  subscriptionStageActivationCredentials: Credentials;
  fundTradingProgramIdToActivate: string;
  userSubscriptionPayoutId: string;
};

class SubscriptionDetailsPage extends React.Component<SubscriptionDetailsPageProps, SubscriptionDetailsPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: SubscriptionDetailsPageState = {
    isLoading: true,
    list: [],
    filter: { page: 1, pageSize: 128, total: 0 },
    isDeactivateModalOpen: false,
    isCredentialsModalOpen: false,
    isPayoutModalOpen: false,
    isActiveSubscriptionModalOpen: false,
    isActiveSubscriptionStageModalOpen: false,
    subscriptionToActivate: null,
    subscriptionToActivateStage: null,
    subscriptionStageToActivate: null,
    subscriptionToDeactivate: null,
    accountDetails: { username: null, password: null, mfa: null },
    payoutDetails: { status: null, network: null, address: null, payoutAmount: 0 },
    deactivateMessage: null,
    subscriptionActivationCredentials: {
      platformKind: TradingPlatformKind.BONEX_MARGIN,
      uid: null,
      username: null,
      password: null,
      mfa: null
    },
    subscriptionStageActivationCredentials: {
      platformKind: TradingPlatformKind.BONEX_MARGIN,
      uid: null,
      username: null,
      password: null,
      mfa: null
    },
    fundTradingProgramIdToActivate: null,
    userSubscriptionPayoutId: null
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
    const queryParams = new URLSearchParams(this.props.location.search);
    const userSubscriptionId = this.props.params.userSubscriptionId;

    const filter: Filter = {
      userSubscriptionId: userSubscriptionId,

      page: parseInt(queryParams.get("page") || "1", 10),
      pageSize: parseInt(queryParams.get("pageSize") || "128", 10),
      total: 0
    };

    const offset = (filter.page - 1) * filter.pageSize;

    const response = await PortCentralServer.Repo.client.filterViewUserSubscriptionProgressInfos({
      userSubscriptionId: filter.userSubscriptionId,
      limit: filter.pageSize,
      offset: offset
    });

    if (response) {
      filter.total = response.total;

      const statusPriority: { [key in SubscriptionStatus]: number } = {
        [SubscriptionStatus.ACTIVE]: 1,
        [SubscriptionStatus.READY]: 2,
        [SubscriptionStatus.DRAFT]: 3,
        [SubscriptionStatus.FINISHED]: 4,
        [SubscriptionStatus.FAILED]: 5,
        [SubscriptionStatus.NOT_SET]: 6,
        [SubscriptionStatus.PAID]: 7,
        [SubscriptionStatus.ALL]: 8
      };

      response.data.map((subscription) => subscription.fundTradingProgramStageSequence++);

      this.setState(() => {
        return {
          filter: filter,
          list: response.data.sort((a, b) => {
            // Compare based on status priority
            const statusOrderA = statusPriority[a.userSubscriptionStatus];
            const statusOrderB = statusPriority[b.userSubscriptionStatus];

            if (statusOrderA !== statusOrderB) {
              return statusOrderA - statusOrderB;
            }

            // If statuses are the same, compare by startDate in descending order
            const startDateA = new Date(a.userSubscriptionDateStart).getTime();
            const startDateB = new Date(b.userSubscriptionDateStart).getTime();

            return startDateB - startDateA; // Descending order
          }),
          isLoading: false
        };
      });
    } else {
      await this.setState((prevState) => {
        return {
          isLoading: false
        };
      });
    }
  };

  calculateProgress = (realizedPnl: number, profitTargetPercantage: number, accountSize: number) => {
    const profitTargetAmount = (profitTargetPercantage / 100) * accountSize;
    const progress = (realizedPnl / profitTargetAmount) * 100;

    if (progress > 100) {
      return 100;
    } else if (progress < 0 || !progress) {
      return 0;
    }

    return Math.round(progress);
  };

  fetchSubscriptionDetails = async (userId: string, userSubscriptionId: string) => {
    const credentials = await PortCentralServer.Repo.client.getUserSubscriptionCredentialById(userSubscriptionId);

    if (credentials) {
      this.setState({
        accountDetails: {
          username: credentials.username,
          password: credentials.password,
          mfa: credentials.mfa
        },
        isCredentialsModalOpen: true
      });
    }
  };

  // fetchPayoutDetails = async (userId: string, userSubscriptionPayoutId: string) => {
  //   const payout = await PortCentralServer.Repo.client.getUserSubscriptionPurchase(userId, userSubscriptionPayoutId);

  //   if (payout) {
  //     this.setState({
  //       payoutDetails: {
  //         status: payout.status,
  //         network: payout.network,
  //         address: payout.address,
  //         payoutAmount: payout.payoutAmount
  //       },
  //       isPayoutModalOpen: true
  //     });
  //   }
  // };

  displayActionButtons = (userSubscription: ViewUserSubscriptionProgressInfoDto) => {
    const buttons = [];

    const nextStage = userSubscription.fundTradingProgramStages.find((stage) => stage.sequence === userSubscription.fundTradingProgramStageSequence);

    const nextFundTradingProgramStageId = nextStage ? nextStage.fundTradingProgramStageId : null;

    let count = 0;

    if (userSubscription.userSubscriptionStatus !== SubscriptionStatus.FAILED) {
      buttons.push(
        <button
          key={count++}
          onClick={() => this.setState({ isDeactivateModalOpen: true, subscriptionToDeactivate: userSubscription.userSubscriptionId })}
          className="btn-rounded btn btn-warning me-2"
        >
          Deactivate
        </button>
      );
    }

    if (
      userSubscription.userSubscriptionStatus === SubscriptionStatus.ACTIVE ||
      userSubscription.userSubscriptionStatus === SubscriptionStatus.FAILED ||
      userSubscription.userSubscriptionStatus === SubscriptionStatus.FINISHED
    ) {
      buttons.push(
        <Link key={count++} to={`/subscriptions/${userSubscription.userSubscriptionId}/progress`} className="btn-rounded btn btn-primary me-2">
          Progress
        </Link>
      );
    } else if (userSubscription.userSubscriptionStatus === SubscriptionStatus.PAID) {
      buttons.push(
        <button
          key={count++}
          onClick={() => this.setState({ isActiveSubscriptionModalOpen: true, subscriptionToActivate: userSubscription.userSubscriptionId })}
          className="btn-rounded btn btn-primary me-2"
        >
          Activate Subscription
        </button>
      );
    }

    // if (
    //   userSubscription.userSubscriptionStatus === SubscriptionStatus.FINISHED &&
    //   (userSubscription.userSubscriptionPayoutStatus === SubscriptionPayoutStatus.PENDING || userSubscription.userSubscriptionPayoutStatus === SubscriptionPayoutStatus.COMPLETED) &&
    //   userSubscription.userSubscriptionPayoutId
    // ) {
    //   buttons.push(
    //     <button
    //       key={count++}
    //       onClick={() => {
    //         this.fetchPayoutDetails(userSubscription.userSubscriptionUserId, userSubscription.userSubscriptionPayoutId);

    //         this.setState({
    //           isPayoutModalOpen: true,
    //           subscriptionToActivate: userSubscription.userSubscriptionId,
    //           userSubscriptionPayoutId: userSubscription.userSubscriptionPayoutId
    //         });
    //       }}
    //       className="btn-rounded btn btn-primary me-2"
    //     >
    //       Payout
    //     </button>
    //   );
    // }

    if (userSubscription.currentStageIndexStatus === StageIndexStatus.PROCESSED && nextFundTradingProgramStageId) {
      buttons.push(
        <button
          key={count++}
          onClick={() =>
            this.setState({
              isActiveSubscriptionStageModalOpen: true,
              subscriptionToActivateStage: userSubscription.userSubscriptionId,
              subscriptionStageToActivate: nextFundTradingProgramStageId
            })
          }
          className="btn-rounded btn btn-primary me-2"
        >
          Activate Stage
        </button>
      );
    }

    buttons.push(
      <Link key={count++} to={`/payouts?userSubscriptionId=${userSubscription.userSubscriptionId}`} className="btn-rounded btn btn-dark me-2">
        Payouts
      </Link>
    );

    return buttons;
  };

  confirmCancel = async () => {
    if (this.state.subscriptionToDeactivate) {
      const subscriptionDeactivated = await PortCentralServer.Repo.client.deactivateUserSubscription({
        userSubscriptionId: this.state.subscriptionToDeactivate,
        message: this.state.deactivateMessage
      });

      this.setState({
        isDeactivateModalOpen: false,
        subscriptionToDeactivate: null,
        deactivateMessage: null
      });

      if (subscriptionDeactivated) {
        window.location.reload();
      }
    }
  };

  cancelModalClose = () => {
    this.setState({
      isDeactivateModalOpen: false,
      subscriptionToDeactivate: null,
      deactivateMessage: null
    });
  };

  credentialsModalClose = () => {
    this.setState({
      isCredentialsModalOpen: false,
      accountDetails: {
        username: null,
        password: null,
        mfa: null
      }
    });
  };

  payoutModalClose = () => {
    this.setState({
      isPayoutModalOpen: false,
      payoutDetails: {
        status: null,
        network: null,
        address: null,
        payoutAmount: 0
      }
    });
  };

  payoutSubscription = async () => {
    const payout = await PortCentralServer.Repo.client.completeUserSubscriptionPurchase({ userSubscriptionPayoutId: this.state.userSubscriptionPayoutId, comment: "" });

    if (payout) {
      this.setState({
        isPayoutModalOpen: false,
        payoutDetails: {
          status: SubscriptionPayoutStatus.COMPLETED,
          network: null,
          address: null,
          payoutAmount: 0
        }
      });
    }
  };

  activateSubscriptionModalClose = () => {
    this.setState({
      isActiveSubscriptionModalOpen: false,
      subscriptionToActivate: null
    });
  };

  activateSubscriptionStageModalClose = () => {
    this.setState({
      isActiveSubscriptionStageModalOpen: false,
      subscriptionToActivateStage: null,
      subscriptionStageToActivate: null,
      subscriptionStageActivationCredentials: {
        platformKind: TradingPlatformKind.BONEX_MARGIN,
        uid: null,
        username: null,
        password: null,
        mfa: null
      }
    });
  };

  activateSubscription = async () => {
    const subscriptionActivated = await PortCentralServer.Repo.client.activateUserSubscription({
      userSubscriptionId: this.state.subscriptionToActivate,
      credentials: this.state.subscriptionActivationCredentials
    });

    this.setState({
      isActiveSubscriptionModalOpen: false,
      subscriptionToActivate: null,
      subscriptionActivationCredentials: {
        platformKind: TradingPlatformKind.BONEX_MARGIN,
        uid: null,
        username: null,
        password: null,
        mfa: null
      }
    });

    if (subscriptionActivated) {
      window.location.reload();
    }
  };

  activateSubscriptionStage = async () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const subscriptionStageActivated = await PortCentralServer.Repo.client.activateUserSubscriptionIndex({
      userSubscriptionId: this.state.subscriptionToActivateStage,
      fundTradingProgramStageId: this.state.subscriptionStageToActivate,
      dateStart: tomorrow,
      credentials: this.state.subscriptionStageActivationCredentials
    });

    this.setState({
      isActiveSubscriptionStageModalOpen: false,
      subscriptionStageToActivate: null,
      subscriptionToActivateStage: null,
      subscriptionStageActivationCredentials: {
        platformKind: TradingPlatformKind.BONEX_MARGIN,
        uid: null,
        username: null,
        password: null,
        mfa: null
      }
    });

    if (subscriptionStageActivated) {
      window.location.reload();
    }
  };

  ActivateSubscriptionContent = () => {
    const { subscriptionActivationCredentials } = this.state;

    return (
      <div className="align-items-center text-center">
        <div className="mt-3">
          <h5>Credentials:</h5>
          <div className="mb-2">
            <label>Platform Kind:</label>
            <select
              className="form-control"
              value={subscriptionActivationCredentials.platformKind}
              onChange={(e) =>
                this.setState({
                  subscriptionActivationCredentials: {
                    ...subscriptionActivationCredentials,
                    platformKind: e.target.value as TradingPlatformKind
                  }
                })
              }
            >
              <option value={TradingPlatformKind.BONEX_MARGIN}>BONEX_MARGIN</option>
              <option value={TradingPlatformKind.NOT_SET}>NOT_SET</option>
              {/* Add other platform kinds if needed */}
            </select>
          </div>
          <div className="mb-2">
            <label>UID:</label>
            <input
              type="text"
              className="form-control"
              value={subscriptionActivationCredentials.uid}
              onChange={(e) =>
                this.setState({
                  subscriptionActivationCredentials: {
                    ...subscriptionActivationCredentials,
                    uid: e.target.value
                  }
                })
              }
            />
          </div>
          <div className="mb-2">
            <label>Username:</label>
            <input
              type="text"
              className="form-control"
              value={subscriptionActivationCredentials.username}
              onChange={(e) =>
                this.setState({
                  subscriptionActivationCredentials: {
                    ...subscriptionActivationCredentials,
                    username: e.target.value
                  }
                })
              }
            />
          </div>
          <div className="mb-2">
            <label>Password:</label>
            <input
              type="password"
              className="form-control"
              value={subscriptionActivationCredentials.password}
              onChange={(e) =>
                this.setState({
                  subscriptionActivationCredentials: {
                    ...subscriptionActivationCredentials,
                    password: e.target.value
                  }
                })
              }
            />
          </div>
          <div className="mb-2">
            <label>MFA:</label>
            <input
              type="text"
              className="form-control"
              value={subscriptionActivationCredentials.mfa}
              onChange={(e) =>
                this.setState({
                  subscriptionActivationCredentials: {
                    ...subscriptionActivationCredentials,
                    mfa: e.target.value
                  }
                })
              }
            />
          </div>
        </div>
      </div>
    );
  };

  ActivateSubscriptionStageContent = () => {
    const { subscriptionStageActivationCredentials } = this.state;

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    return (
      <div className="align-items-center text-center">
        <h3>Select Subscription Stage Activation Date</h3>
        <div className="mt-3">
          <h5>Credentials:</h5>
          <div className="mb-2">
            <label>Platform Kind:</label>
            <select
              className="form-control"
              value={subscriptionStageActivationCredentials.platformKind}
              onChange={(e) =>
                this.setState({
                  subscriptionStageActivationCredentials: {
                    ...subscriptionStageActivationCredentials,
                    platformKind: e.target.value as TradingPlatformKind
                  }
                })
              }
            >
              <option value={TradingPlatformKind.BONEX_MARGIN}>BONEX_MARGIN</option>
              <option value={TradingPlatformKind.NOT_SET}>NOT_SET</option>
              {/* Add other platform kinds if needed */}
            </select>
          </div>
          <div className="mb-2">
            <label>UID:</label>
            <input
              type="text"
              className="form-control"
              value={subscriptionStageActivationCredentials.uid}
              onChange={(e) =>
                this.setState({
                  subscriptionStageActivationCredentials: {
                    ...subscriptionStageActivationCredentials,
                    uid: e.target.value
                  }
                })
              }
            />
          </div>
          <div className="mb-2">
            <label>Username:</label>
            <input
              type="text"
              className="form-control"
              value={subscriptionStageActivationCredentials.username}
              onChange={(e) =>
                this.setState({
                  subscriptionStageActivationCredentials: {
                    ...subscriptionStageActivationCredentials,
                    username: e.target.value
                  }
                })
              }
            />
          </div>
          <div className="mb-2">
            <label>Password:</label>
            <input
              type="password"
              className="form-control"
              value={subscriptionStageActivationCredentials.password}
              onChange={(e) =>
                this.setState({
                  subscriptionStageActivationCredentials: {
                    ...subscriptionStageActivationCredentials,
                    password: e.target.value
                  }
                })
              }
            />
          </div>
          <div className="mb-2">
            <label>MFA:</label>
            <input
              type="text"
              className="form-control"
              value={subscriptionStageActivationCredentials.mfa}
              onChange={(e) =>
                this.setState({
                  subscriptionStageActivationCredentials: {
                    ...subscriptionStageActivationCredentials,
                    mfa: e.target.value
                  }
                })
              }
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <BaseModal
              isOpen={this.state.isDeactivateModalOpen}
              content={
                <>
                  <h6>Are you sure you want to cancel this subscription?</h6>
                  <textarea
                    className="form-control mt-2"
                    placeholder="Enter a message"
                    value={this.state.deactivateMessage}
                    onChange={(e) => this.setState({ deactivateMessage: e.target.value })}
                  />
                </>
              }
              title="Confirm Subscription Deactivation"
              onSubmitClick={this.confirmCancel}
              onCloseClick={this.cancelModalClose}
              isWarning={true}
            />

            <BaseModal
              isOpen={this.state.isCredentialsModalOpen}
              content={
                <div>
                  <p>
                    <strong>Username:</strong> {this.state.accountDetails.username}
                  </p>
                  <p>
                    <strong>Password:</strong> {this.state.accountDetails.password}
                  </p>
                  <p>
                    <strong>2FA:</strong> {this.state.accountDetails.mfa}
                  </p>
                </div>
              }
              title="See credentials"
              onSubmitClick={this.credentialsModalClose}
              onCloseClick={this.credentialsModalClose}
            />

            <BaseModal
              isOpen={this.state.isPayoutModalOpen}
              content={
                <div>
                  <p>
                    <strong>Status:</strong> {this.state.payoutDetails.status}
                  </p>
                  <p>
                    <strong>Network:</strong> {this.state.payoutDetails.network}
                  </p>
                  <p>
                    <strong>Address:</strong> {this.state.payoutDetails.address}
                  </p>
                  <p>
                    <strong>Payout amount:</strong> {this.state.payoutDetails.payoutAmount}
                  </p>
                </div>
              }
              title="See payout"
              onSubmitClick={this.state.payoutDetails.status === SubscriptionPayoutStatus.COMPLETED ? this.payoutModalClose : this.payoutSubscription}
              onCloseClick={this.payoutModalClose}
            />

            <BaseModal
              isOpen={this.state.isActiveSubscriptionModalOpen}
              content={<this.ActivateSubscriptionContent />}
              title="Active Subscription"
              onSubmitClick={this.activateSubscription}
              onCloseClick={this.activateSubscriptionModalClose}
            />

            <BaseModal
              isOpen={this.state.isActiveSubscriptionStageModalOpen}
              content={<this.ActivateSubscriptionStageContent />}
              title="Active Subscription Stage"
              onSubmitClick={this.activateSubscriptionStage}
              onCloseClick={this.activateSubscriptionStageModalClose}
            />

            <div className="container-fluid">
              <Breadcrumbs title="Subscriptions" breadcrumbItem="Details" />
              <Row>
                <Col xl={12}>
                  {this.state.isLoading ? (
                    <LoaderComponent />
                  ) : (
                    <>
                      <div>
                        <CardBody>
                          {/* <Form method="get">
                            <Row className="mb-4">
                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-3">
                                  <Label className="form-label">Level</Label>
                                  <EnumSelectControl
                                    enumData={SubscriptionStatus}
                                    name="type"
                                    selectedValue={this.state.filter.status}
                                    className="form-control select2-search-disable"
                                  />
                                </FormGroup>
                              </Col>
                              <div className="col-xl col-sm-6 align-self-end">
                                <div className="mb-3">
                                  <input type="hidden" name="page" value={this.state.filter.page} />
                                  <input type="hidden" name="pageSize" value={this.state.filter.pageSize} />
                                  <Button type="submit" color="primary" className="w-md">
                                    Filter
                                  </Button>
                                </div>
                              </div>
                            </Row>
                          </Form> */}

                          <Row>
                            <Col xl={12} className="">
                              <div className="table-responsive scroll-horizontal-hidden">
                                <Row>
                                  {(this.state.list || []).map((item: ViewUserSubscriptionProgressInfoDto, key: number) => (
                                    <Col xl={3} key={key}>
                                      <div className="card">
                                        <div className="card-body text-center">
                                          <div className="d-flex flex-column align-items-center">
                                            <h5 className="text-truncate font-size-15 mb-1">
                                              <a className="text-dark" href="/projects-overview/9">
                                                {item.fundTradingProgramTitle}: ${Number(item.fundTradingProgramAccountSize).toLocaleString()}
                                              </a>
                                            </h5>
                                            <div className="d-flex flex-column align-items-center mt-2">
                                              <span
                                                className={`badge rounded-pill font-size-12 ${item.userSubscriptionStatus === SubscriptionStatus.ACTIVE
                                                    ? "badge-soft-success"
                                                    : item.userSubscriptionStatus === SubscriptionStatus.DRAFT
                                                      ? "badge-soft-warning"
                                                      : item.userSubscriptionStatus === SubscriptionStatus.READY
                                                        ? "badge-soft-primary"
                                                        : item.userSubscriptionStatus === SubscriptionStatus.FINISHED || item.userSubscriptionStatus === SubscriptionStatus.PAID
                                                          ? "badge-soft-info"
                                                          : "badge-soft-danger"
                                                  }`}
                                                id="task-status"
                                              >
                                                {item.userSubscriptionStatus}
                                              </span>
                                              {item.userSubscriptionStatus === SubscriptionStatus.ACTIVE || item.userSubscriptionStatus === SubscriptionStatus.READY ? (
                                                <span
                                                  onClick={() => this.fetchSubscriptionDetails(item.userSubscriptionUserId, item.currentStageIndexUserSubscriptionCredentialId)}
                                                >
                                                  View Account Details
                                                </span>
                                              ) : (
                                                <span className="visibility-hidden">View Account Details</span>
                                              )}
                                              <ApexRadial
                                                dataColors='["--bs-primary"]'
                                                progress={this.calculateProgress(
                                                  item.currentStageIndexTotalRealizedPnl,
                                                  item.fundTradingProgramStageProfitTarget,
                                                  item.fundTradingProgramAccountSize
                                                )}
                                              />
                                            </div>
                                          </div>

                                          <Row className="justify-content-center mt-3">
                                            <Col md={12}>
                                              {item.userSubscriptionStatus === SubscriptionStatus.DRAFT ||
                                                item.userSubscriptionStatus === SubscriptionStatus.READY ||
                                                item.userSubscriptionStatus === SubscriptionStatus.PAID ? (
                                                <>
                                                  <p className="mb-1 visibility-hidden">Activate to show statistics</p>
                                                  <p className="mb-1">
                                                    <i className="bx bxs-info-circle"></i>
                                                    User has to activate to show statistics
                                                  </p>
                                                  <p className="mb-1 visibility-hidden">Activate to show statistics</p>
                                                </>
                                              ) : (
                                                <>
                                                  <p className="mb-1">
                                                    <i className="bx bxs-calendar"></i> Start Date: {moment(item.userSubscriptionDateStart).format("YYYY-MM-DD")}
                                                  </p>
                                                  <p className="mb-1">
                                                    <i className="bx bx-loader-circle"></i> Stage:{" "}
                                                    {item.fundTradingProgramStageType === "PHASE" ? item.fundTradingProgramStageSequence : "LIVE"}
                                                  </p>
                                                  <p className="mb-1">
                                                    <i className="bx bx-money"></i> PNL: ${Number(item.currentStageIndexTotalRealizedPnl).toFixed(2)} USDT
                                                  </p>
                                                </>
                                              )}
                                            </Col>
                                          </Row>
                                          {item.currentStageIndexFlags && item.currentStageIndexFlags.includes(SubscriptionIndexFlagType.LARGE_PROFIT_IN_ONE_ORDER) && (
                                            <div className="mb-1">
                                              <i className="text-danger bx bxs-info-circle"></i>
                                              <span className="text-danger"> Single trade profit limit exceeded.</span>
                                            </div>
                                          )}
                                          {item.currentStageIndexFlags && item.currentStageIndexFlags.includes(SubscriptionIndexFlagType.ORDER_IN_WRONG_CONTRACT) && (
                                            <div className="mb-1">
                                              <i className="text-danger bx bxs-info-circle"></i>
                                              <span className="text-danger"> Unsupported trade protocol.</span>
                                            </div>
                                          )}
                                        </div>
                                        <div className="px-4 py-3 border-top text-center">{this.displayActionButtons(item)}</div>
                                      </div>
                                    </Col>
                                  ))}
                                </Row>

                                <PagerComponent currentPage={this.state.filter.page} pageSize={this.state.filter.pageSize} totalItems={this.state.filter.total} />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(SubscriptionDetailsPage);
