import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { AppContext } from "../context/AppProvider";
import { Layout } from "../layout";
import { AuthCookieManager } from "../lib/AuthCookieManager";
import withRouterHook from "../withRouterHook";

export type SomePageProps = {};

type SomePageState = {};

class SomePage extends React.Component<SomePageProps, SomePageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  componentDidMount = async () => {
    // ...
  };

  // #region handlers
  handleToastClick = (e: any) => {
    // toast.error("Wow so easy!", {
    //   position: "bottom-right",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: false,
    //   progress: undefined,
    //   theme: "dark",
    // });

    this.context.notifyError("toast from some page");
  };

  handleClearCookieClick = (e: any) => {
    AuthCookieManager.removeSessionCookies();
  };
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="debug">
              <div>{this.context?.connected ? <span>Connected</span> : <span>Not Connected</span>}</div>
            </div>

            <div className="debug">
              <div>
                <button
                  onClick={(e) => {
                    this.context?.setConnected(!this.context?.connected);
                  }}
                >
                  toggle
                </button>
              </div>
            </div>
            <br />
            <div className="debug mt-50">
              <div>
                <button
                  onClick={(e) => {
                    this.handleToastClick(e);
                  }}
                >
                  toast
                </button>
                <br />
                <button
                  onClick={(e) => {
                    this.handleClearCookieClick(e);
                  }}
                >
                  Clear Cookies
                </button>
              </div>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(SomePage);
