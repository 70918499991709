import "flatpickr/dist/themes/material_blue.css";
import moment from "moment";
import React from "react";
import { useLocation } from "react-router-dom";
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row, Table } from "reactstrap";
import { UserInfoButtons } from "../../components/controls/UserInfoButtons";
import LoaderComponent from "../../components/LoaderComponent";
import PagerComponent from "../../components/PagerComponent";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { UserAffiliateDto } from "../../lib/drivers/dto/affiliate/UserAffiliateDto";
import withRouterHook from "../../withRouterHook";
import { UserAffiliateInfoButtons } from "../../components/controls/UserAffiliateInfoButtons";

type AffiliatesPageProps = {
  location?: ReturnType<typeof useLocation>;
};

type Filter = {
  userAffiliateId?: string;
  userId?: string;
  page: number;
  pageSize: number;
  total: number;
};

type AffiliatesPageState = {
  isLoading: boolean;
  startDate: Date;
  list: UserAffiliateDto[];

  // filter
  filter: Filter;
};

class AffiliatesPage extends React.Component<AffiliatesPageProps, AffiliatesPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: AffiliatesPageState = {
    isLoading: true,
    startDate: new Date(),
    list: [],

    // filter
    filter: {
      userAffiliateId: "",
      userId: "",
      page: 1,
      pageSize: 128,
      total: 0
    }
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
    const queryParams = new URLSearchParams(this.props.location.search);

    const filter: Filter = {
      userId: queryParams.get("userId") || "",
      userAffiliateId: queryParams.get("userAffiliateId") || "",

      page: parseInt(queryParams.get("page") || "1", 10),
      pageSize: parseInt(queryParams.get("pageSize") || "128", 10),
      total: 0
    };

    const offset = (filter.page - 1) * filter.pageSize;

    const response = await PortCentralServer.Repo.client.filterUserAffiliates({
      userId: filter.userId,
      userAffiliateId: filter.userAffiliateId,
      limit: filter.pageSize,
      offset: offset
    });

    if (response) {
      filter.total = response.total;

      await this.setState((prevState) => {
        return {
          filter: filter,
          list: response.data,
          isLoading: false
        };
      });
    } else {
      await this.setState((prevState) => {
        return {
          isLoading: false
        };
      });
    }
  };

  componentDidUpdate = async (prevProps: AffiliatesPageProps, prevState: AffiliatesPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  // #region Renders
  handlePageSizeChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newPageSize = parseInt(e.target.value, 10);
    await this.setState(
      (prevState) => ({
        filter: {
          ...prevState.filter,
          pageSize: newPageSize,
          page: 1 // Reset to page 1 when page size changes
        }
      }),
      () => {
        // Assuming you want to reload data and update URL here
        // this.props.history.push(`?page=1&pageSize=${newPageSize}`);
        // this.loadData();
      }
    );
  };
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Affiliates" breadcrumbItem="List" />
              <Row>
                <Col xl={12}>
                  {this.state.isLoading ? (
                    <LoaderComponent />
                  ) : (
                    <>
                      <Card>
                        <CardBody>
                          <Form method="get">
                            <Row className="mb-4">
                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-3">
                                  <Label className="form-label">User Id</Label>
                                  <Input className="form-control" name="userId" defaultValue={this.state.filter.userId} />
                                </FormGroup>
                              </Col>

                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-3">
                                  <Label className="form-label">Affiliate Id</Label>
                                  <Input className="form-control" name="userAffiliateId" defaultValue={this.state.filter.userAffiliateId} />
                                </FormGroup>
                              </Col>

                              <div className="col-xl col-sm-6 align-self-end">
                                <div className="mb-3">
                                  <input type="hidden" name="page" value={this.state.filter.page} />
                                  <input type="hidden" name="pageSize" value={this.state.filter.pageSize} />
                                  <Button type="submit" color="primary" className="w-md">
                                    Filter
                                  </Button>
                                </div>
                              </div>
                            </Row>
                          </Form>

                          <Row>
                            <Col xl={12}>
                              <div className="table-responsive">
                                <Table className="table table-bordered m-0">
                                  <thead>
                                    <tr>
                                      <th>userId</th>
                                      <th>userAffiliateId</th>
                                      <th>email</th>
                                      <th>invitationCode</th>
                                      <th>activeYn</th>
                                      <th>payoutNetwork</th>
                                      <th>payoutAddress</th>
                                      <th>purchaseSharePercentages</th>
                                      <th>discountPercentages</th>
                                      <th>dateCreated</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {(this.state.list || []).map((item: UserAffiliateDto, key: number) => (
                                      <tr key={key}>
                                        <td>
                                          <UserInfoButtons userId={item.userId} />
                                        </td>
                                        <td>
                                          <UserAffiliateInfoButtons userAffiliateId={item.userAffiliateId} />
                                        </td>
                                        <td>{item.email}</td>
                                        <td>{item.invitationCode}</td>
                                        <td>{`${item.activeYn}`}</td>
                                        <td>{item.payoutNetwork}</td>
                                        <td>{item.payoutAddress}</td>
                                        <td>{item.purchaseSharePercentages}</td>
                                        <td>{item.discountPercentages}</td>
                                        <td>{moment(item.dateCreated).format("YYYY-MM-DD HH:mm:ss")}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                <FormGroup>
                                  <Label for="pageSizeSelect">Page Size</Label>
                                  <select name="pageSize" id="pageSizeSelect" onChange={this.handlePageSizeChange} value={this.state.filter.pageSize}>
                                    <option value="32">32</option>
                                    <option value="64">64</option>
                                    <option value="128">128</option>
                                  </select>
                                </FormGroup>
                                <PagerComponent currentPage={this.state.filter.page} pageSize={this.state.filter.pageSize} totalItems={this.state.filter.total} />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(AffiliatesPage);
